import React, { useEffect, useState } from "react";
import AdminCandidateCard from "../components/AdminCandidateCard";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import defaultConfig from "../aws-configure";
import jobList from "./../jobList.json";

export default function AdminCandidates() {
  const [userData, setUserData] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  const [candidateList, setCandidateList] = useState(null);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedFirstTimer, setSelectedFirstTimer] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [genderDropdownOpen, setGenderDropdownOpen] = useState(false);
  const [positionDropdownOpen, setPositionDropdownOpen] = useState(false);
  const [firstTimerDropdownOpen, setFirstTimerDropdownOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      localStorage.setItem("userData", JSON.stringify(userData));
    }
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("userToken");

        const candidateParam = new URLSearchParams({
          role: "Candidate",
        });

        const candidateResponse = await axios.get(
          `${defaultConfig.apiUrl}adminGetUsersByRole?${candidateParam}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // Update candidateData state with the data received from the API
        setCandidateList(candidateResponse.data);
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenderChange = (value) => {
    setSelectedGender(value);
  };

  const handlePositionChange = (value) => {
    setSelectedPosition(value);
  };

  const handleFirstTimerChange = (value) => {
    setSelectedFirstTimer(value);
  };

  const handleEmploymentStatusChange = (value) => {
    setSelectedStatus(value);
  };
  const filteredCandidateList = candidateList
    ? candidateList.filter(
        (candidate) =>
          (selectedGender === "" ||
            candidate.gender === selectedGender ||
            selectedGender === "All Genders") &&
          (selectedPosition === "" ||
            candidate.positions?.primary === selectedPosition ||
            candidate.positions?.secondary === selectedPosition ||
            selectedPosition === "All Positions") &&
          (selectedFirstTimer === "" ||
            candidate.firstTimer.toString() === selectedFirstTimer ||
            selectedFirstTimer === "First Timers") &&
          (selectedStatus === "" ||
            selectedStatus === "All Statuses" ||
            candidate.employmentStatus === selectedStatus)
      )
    : [];

  const genderOptions = ["All Genders", "Male", "Female"];
  const sortedJobList = [...jobList].sort();
  const positionOptions = sortedJobList;
  const firstTimerOptions = ["First Timers", "true", "false"];
  const employmentStatusOptions = [
    "All Statuses",
    "Available",
    "Being Interviewed",
    "Hired",
  ];

  // const handleStatusChange = async (candidateId, newStatus) => {
  //   try {
  //     const updatedCandidateList = candidateList.map((candidate) =>
  //       candidate.id === candidateId
  //         ? { ...candidate, employmentStatus: newStatus }
  //         : candidate
  //     );
  //     setCandidateList(updatedCandidateList);
  //   } catch (error) {
  //     console.error("Error updating candidate status:", error);
  //   }
  // };

  return (
    <section>
      <MDBContainer>
        <MDBRow>
          <h4>View Available Candidate</h4>
          <p>View a list of all our available candidates</p>
        </MDBRow>
        <MDBRow className="mb-3">
          <MDBCol size="auto">
            <Dropdown
              isOpen={genderDropdownOpen}
              toggle={() => setGenderDropdownOpen(!genderDropdownOpen)}
            >
              <DropdownToggle caret>
                {selectedGender ? selectedGender : "All Genders"}
              </DropdownToggle>
              <DropdownMenu>
                {genderOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleGenderChange(option)}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </MDBCol>
          <MDBCol size="auto">
            <Dropdown
              isOpen={positionDropdownOpen}
              toggle={() => setPositionDropdownOpen(!positionDropdownOpen)}
            >
              <DropdownToggle caret>
                {selectedPosition ? selectedPosition : "All Positions"}
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
                {positionOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handlePositionChange(option)}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </MDBCol>
          <MDBCol size="auto">
            <Dropdown
              isOpen={firstTimerDropdownOpen}
              toggle={() => setFirstTimerDropdownOpen(!firstTimerDropdownOpen)}
            >
              <DropdownToggle caret>
                {selectedFirstTimer !== ""
                  ? selectedFirstTimer === "true"
                    ? "First Timer"
                    : "Not First Timer"
                  : "All"}
              </DropdownToggle>
              <DropdownMenu>
                {firstTimerOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleFirstTimerChange(option)}
                  >
                    {option === "true"
                      ? "First Timer"
                      : option === "false"
                      ? "Not First Timer"
                      : option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </MDBCol>
          <MDBCol size="auto">
            <Dropdown
              isOpen={statusDropdownOpen}
              toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
            >
              <DropdownToggle caret>
                {selectedStatus ? selectedStatus : "Employment Status"}
              </DropdownToggle>
              <DropdownMenu>
                {employmentStatusOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleEmploymentStatusChange(option)}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </MDBCol>
        </MDBRow>
        <div className="container">
          <MDBRow className="d-flex flex-column flex-md-row">
            {filteredCandidateList.map((candidate, index) => (
              <MDBCol className="col-md-3" key={index}>
                <AdminCandidateCard
                  candidateData={candidate}
                  setCandidateData={setCandidateData}
                />
              </MDBCol>
            ))}
          </MDBRow>
        </div>
      </MDBContainer>
    </section>
  );
}

import React from "react";

export default function Footer() {
  return (
    <section className="">
      <footer className="text-center text-white footer-bg">
        <div className="text-center p-3 footer-alt-bg">
          © 2024 Copyright:&nbsp;
          <a className="text-white" href="/">
            H2B Direct
          </a>
        </div>
      </footer>
    </section>
  );
}

import React from "react";
import Hero from "../components/Hero";
import ImageCard from "../components/ImageCard";
import { Button, Table } from "reactstrap";
import EmployerOverview from "./../images/Group 227.png";
import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import CandView from "./../images/Group 228.png";

const csvData = `Andorra,The Kingdom of Eswatini,Madagascar,Saint Lucia
Argentina,Fiji,Malta,San Marino
Australia,Finland,Mauritius,Serbia
Austria,France,Mexico,Singapore
Barbados,Germany,Monaco,Slovakia
Belgium,Greece,Mongolia,Slovenia
Bolivia,Grenada,Montenegro,Solomon Islands
Bosnia and Herzegovina,Guatemala,Mozambique,South Africa
Brazil,Haiti,Nauru,South Korea
Brunei,Honduras,The Netherlands,Spain
Bulgaria,Hungary,New Zealand,St. Vincent and the Grenadines
Canada,Iceland,Nicaragua,Sweden
Chile,Ireland,North Macedonia,Switzerland
Colombia,Israel,Norway,Taiwan*
Costa Rica,Italy,Panama,Thailand
Croatia,Jamaica,Papua New Guinea,Timor-Leste
Czech Republic,Japan,Peru,Turkey
Denmark,Kiribati,Philippines,Tuvalu
Dominican Republic,Latvia,Poland,Ukraine
Ecuador,Liechtenstein,Portugal,United Kingdom
El Salvador,Lithuania,Republic of Cyprus,Uruguay
Estonia,Luxembourg,Romania,Vanuatu`;

export default function Employers() {
  const heroArray = {
    image: require("../images/employees-business-meeting.jpg"),
    headerText: "Employers",
    subText:
      "Enhance your competitive advantage in selecting candidates from our pool of top-tier talents.",
  };

  const rows = csvData.split("\n");

  return (
    <div>
      <div>
        <Hero
          image={heroArray.image}
          headerText={heroArray.headerText}
          subText={heroArray.subText}
        />
      </div>
      <div className="red-background">
        <p className="text-center">
          We present H2B candidates from all countries participating in the H2B
          Non-Immigrant Program. Explore our diverse pool of skilled
          professionals ready to meet your workforce needs.
        </p>
      </div>
      <div className="page-body">
        <div className="container" style={{ padding: "0 20px 30px 20px" }}>
          <section>
            <ImageCard
              imageSide="right"
              hasHeader="false"
              cardParagraph="Seeking International workers? You are in the right place. On H2BDirect you are
              able to hand-pick candidates from across the world, by viewing concise photo
              profiles showing their experiences which will be valuable to your workforce.
              Whether you are hiring 2 or 500 Candidates, you have access to thousands of
              eager international workers in one central location on an easy-to-use platform
              with filter options to find your best candidates. We also have Immigration
              Lawyers, Local agents to assist with Consulate/Embassy visits, Housing agents
              and J1, Q1, L, O and C1D hiring options and many more resources."
              showbtn="false"
              cardPhoto={CandView}
            />
          </section>
        </div>
        <hr
          style={{
            width: "50%",
            margin: "0 auto",
            color: "#DE3163",
            height: "3px",
          }}
        />
      </div>
      <div className="page-body">
        <div
          className="container text-center"
          style={{ padding: "0 20px 30px 20px" }}
        >
          <h3>So Let’s Recap. You get:</h3>
          <MDBListGroup style={{ minWidthL: "22rem" }} light>
            <MDBListGroupItem>Simple Account Set-Up</MDBListGroupItem>
            <MDBListGroupItem>
              Access to Thousands of International Candidates with just a few
              clicks
            </MDBListGroupItem>
            <MDBListGroupItem>
              Easy-to-use algorithms for searches example “10 chefs, Jamaica,
              returning”
            </MDBListGroupItem>
            <MDBListGroupItem>
              Access to Immigration lawyers and Agents to complete
              paperwork/Embassy
            </MDBListGroupItem>
            <MDBListGroupItem>
              Access to Recruiters/Virtual Assistants to conduct interviews for
              you
            </MDBListGroupItem>
            <MDBListGroupItem>
              A place to find everything all at once
            </MDBListGroupItem>
          </MDBListGroup>
        </div>
      </div>
      <div className="page-body alt-section">
        <div
          className="container text-center"
          style={{ padding: "0 20px 30px 20px" }}
        >
          <h3>Countries Qualified for H2B Visas</h3>
          <br />
          <hr
            style={{
              width: "15%",
              margin: "0 auto",
              color: "#DE3163",
              height: "3px",
            }}
          />
          <br />
          <Table bordered borderless responsive size="sm" striped>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.split(",").map((cell, cellIndex) => (
                    <td key={cellIndex} className="text-center">
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div style={{ padding: "20px" }}>
        <div className="container text-center">
          <h5>Ready to work with us?</h5>
          <p>Come on board! Bring a colleague or two with you.</p>
          <Button color="danger" href="/employer-signup">
            Sign Up!
          </Button>
        </div>
      </div>
    </div>
  );
}

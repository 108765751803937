import ProfileOverview from "./../components/ProfileOverview";
import EditProfile from "./../components/EditProfile";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import Logout from "../pages/Signup/Logout";
import EmployerDashboard from "../components/EmployerDashboard";
import Candidates from "../components/CandidatesView";
import EditEmployerProfile from "../components/EditEmployerProfile";
import AdminDashboard from "../components/AdminDashboard";
import AdminCandidatesView from "../components/AdminCandidatesView";
import AgencyDashboard from "../components/AgencyDashboard";
import EditAgencyProfile from "../components/EditAgencyProfile";

const AppRoutes = [
  {
    path: "/app/candidate/profile",
    element: <ProfileOverview />,
    state: "overview",
    allowedTypes: ["Candidate"],
    sidebarProps: {
      displayText: "Overview",
      icon: <SpaceDashboardOutlinedIcon />,
    },
  },
  {
    path: "/app/candidate/edit",
    element: <EditProfile />,
    state: "editProfile",
    allowedTypes: ["Candidate"],
    sidebarProps: {
      displayText: "Edit Profile",
      icon: <EditOutlinedIcon />,
    },
  },
  {
    path: "/app/employer/dashboard",
    element: <EmployerDashboard />,
    state: "dashboard",
    allowedTypes: ["Employer"],
    sidebarProps: {
      displayText: "Dashboard",
      icon: <SpaceDashboardOutlinedIcon />,
    },
  },
  {
    path: "/app/employer/view-candidates",
    element: <Candidates />,
    state: "view-candidates",
    allowedTypes: ["Employer"],
    sidebarProps: {
      displayText: "View Candidates",
      icon: <PeopleOutlineOutlinedIcon />,
    },
  },
  {
    path: "/app/employer/settings",
    element: <EditEmployerProfile />,
    state: "editProfile",
    allowedTypes: ["Employer"],
    sidebarProps: {
      displayText: "Edit Profile",
      icon: <EditOutlinedIcon />,
    },
  },
  {
    path: "/app/admin/dashboard",
    element: <AdminDashboard />,
    state: "adminDashboard",
    allowedTypes: ["Admin"],
    sidebarProps: {
      displayText: "Dashboard",
      icon: <SpaceDashboardOutlinedIcon />,
    },
  },
  {
    path: "/app/admin/candidates",
    element: <AdminCandidatesView />,
    state: "viewCandidates",
    allowedTypes: ["Admin"],
    sidebarProps: {
      displayText: "Candidates",
      icon: <SpaceDashboardOutlinedIcon />,
    },
  },
  {
    path: "/app/agency/dashboard",
    element: <AgencyDashboard />,
    state: "agencyDashboard",
    allowedTypes: ["Agency"],
    sidebarProps: {
      displayText: "Dashboard",
      icon: <SpaceDashboardOutlinedIcon />,
    },
  },
  {
    path: "/app/agency/settings",
    element: <EditAgencyProfile />,
    state: "agencySetting",
    allowedTypes: ["Agency"],
    sidebarProps: {
      displayText: "Setting",
      icon: <EditOutlinedIcon />,
    },
  },
  {
    path: "/app/logout",
    element: <Logout />,
    state: "logout",
    allowedTypes: ["Employer", "Candidate", "Admin", "Agency"],
    sidebarProps: {
      displayText: "Logout",
      icon: <LogoutOutlinedIcon />,
    },
  },
];

export default AppRoutes;

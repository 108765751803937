import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import axios from "axios";
import DefaultPhoto from "./../images/DefaultPhoto.jpg";
import defaultConfig from "../aws-configure";

export default function CandidateCard({
  candidateData,
  setCandidateData,
  setCandidateList,
  candidateList,
  onUpdateStatus,
  selectedPosition,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectCandidateLoading, setSelectCandidateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem("candidateData");
    if (storedUserData) {
      setCandidateData(JSON.parse(storedUserData));
    }
  }, []);

  useEffect(() => {
    if (candidateData) {
      localStorage.setItem("candidateData", JSON.stringify(candidateData));
    }
  }, [candidateData]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectCandidate = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    setSelectCandidateLoading(true);

    let jobType = candidateData.positions.primary;

    if (selectedPosition === "All Positions" || selectedPosition === "") {
      jobType = candidateData.positions.primary;
    } else {
      jobType = selectedPosition; // Use selected position if it's not empty or "All Positions"
    }

    const createCandidateInterview = {
      candidateId: candidateData.id,
      jobType: jobType,
      // jobDescription: "Do Something",
    };

    try {
      const interviewCreated = await axios.post(
        `${defaultConfig.apiUrl}createInterview`,
        createCandidateInterview,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      onUpdateStatus(candidateData.id, "Being Interviewed");
      const updatedCandidateList = candidateList.map((candidate) => {
        if (candidate.id === candidateData.id) {
          // Update the candidate's employmentStatus to "Being Interviewed"
          return {
            ...candidate,
            employmentStatus: "Being Interviewed",
          };
        }
        return candidate;
      });

      // Set the updated candidateList back to the state
      setCandidateList(updatedCandidateList);
      setUpdateLoading(false);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setSelectCandidateLoading(false);
    }
  };

  return (
    <div className="container flex-fill candidate-cards">
      <div className="card" style={{ borderRadius: "15px" }}>
        <div className="card-body p-4">
          <div className="text-center text-black">
            <div className="">
              <img
                src={
                  candidateData?.photoUrl
                    ? candidateData.photoUrl
                    : DefaultPhoto
                }
                alt="Generic placeholder"
                className="img-fluid"
                style={{
                  width: "220px",
                  height: "200px",
                  maxHeight: "220px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div className="">
              <h5 className="mb-1" style={{ paddingTop: "10px" }}>
                {candidateData.firstName} {candidateData.lastName}
              </h5>
              <p className="mb-2 pb-1" style={{ color: "#2b2a2a" }}>
                {candidateData.country}
              </p>
              <div
                className="d-flex justify-content rounded-3 p-2 mb-2"
                style={{ backgroundColor: "#efefef" }}
              >
                <div className="col-lg-6">
                  <p className="small  text-muted mb-1">Position 1</p>
                  <p className="mb-0">
                    <span className="badge rounded-pill bg-primary">
                      {candidateData.positions?.primary}
                    </span>{" "}
                  </p>
                </div>
                <div className="col-lg-6">
                  <p className="small text-muted mb-1">Position 2</p>
                  <span className="badge rounded-pill bg-secondary">
                    {candidateData.positions?.secondary}
                  </span>
                </div>
              </div>
              <div className="d-flex pt-1">
                <span>
                  <Button onClick={toggleModal}>View More</Button>
                </span>
                <span style={{ marginLeft: "20px" }}>
                  <Button color="primary" onClick={handleSelectCandidate}>
                    {selectCandidateLoading ? "Selecting..." : "Select"}
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} toggle={toggleModal} size="xl" centered scrollable>
        <ModalBody className="modal-height">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="4">
                <MDBCard className="mb-4">
                  <MDBCardBody className="text-center">
                    <MDBCardImage
                      src={
                        candidateData?.photoUrl
                          ? candidateData.photoUrl
                          : DefaultPhoto
                      }
                      alt="avatar"
                      className="rounded"
                      style={{
                        width: "220px",
                        height: "220px",
                        maxHeight: "220px",
                        paddingBottom: "10px",
                      }}
                      fluid
                    />
                    <h2 className="text-muted mb-1">
                      {candidateData &&
                        `${candidateData.firstName} ${candidateData.lastName}`}
                    </h2>
                    <div className="d-flex justify-content-center mb-1">
                      <p>
                        <span className="badge rounded-pill bg-primary">
                          {candidateData &&
                            candidateData.positions &&
                            candidateData.positions.primary}
                        </span>{" "}
                        <span className="badge rounded-pill bg-secondary">
                          {candidateData &&
                            candidateData.positions &&
                            candidateData.positions.secondary}
                        </span>
                      </p>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol lg="8">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Email Address</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && `${candidateData.email}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Telephone Number</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && `${candidateData.phoneNumber}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Address</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData &&
                            `${candidateData.address}, ${candidateData.country}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Gender</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && `${candidateData.gender}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Education Level</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          Tertiary
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>First Time Applicant</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && candidateData.firstTimer === "true"
                            ? "Yes"
                            : "No"}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {candidateData &&
                candidateData.workExperiences &&
                candidateData.workExperiences.map((experience, index) => (
                  <MDBCol md="6" key={index}>
                    <MDBCard className="mb-4">
                      <MDBCardBody>
                        <MDBCardText className="mb-4">
                          <span className="text-primary font-italic me-1">
                            Work Experience
                          </span>
                        </MDBCardText>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Company</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.companyName}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Location</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.state}, {experience.country}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Position</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.position}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Start Date</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.startDate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>End Date</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.endDate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="12" className="mb-2">
                            <MDBCardText>Job Description</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="12">
                            <MDBCardText className="text-muted">
                              {experience.jobDescription}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
            </MDBRow>
          </MDBContainer>
        </ModalBody>
      </Modal>
    </div>
  );
}

import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfig";

const SidebarItem = ({ item, userData }) => {
  const { appState } = useSelector((state) => state.appState);

  // Function to check if the user is authorized to access the sidebar item
  const isAuthorized = (userType, allowedTypes) => {
    return allowedTypes.includes(userType);
  };

  return item.sidebarProps &&
    item.path &&
    isAuthorized(userData.role, item.allowedTypes) ? (
    <ListItemButton
      component={Link}
      to={item.path}
      sx={{
        "&:hover": {
          backgroundColor: colorConfigs.sidebar.hoverBg,
        },
        backgroundColor:
          appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
        paddingY: "12px",
        paddingX: "24px",
      }}
    >
      <ListItemIcon
        sx={{
          color: colorConfigs.sidebar.color,
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      {item.sidebarProps.displayText}
    </ListItemButton>
  ) : null;
};

export default SidebarItem;

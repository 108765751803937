import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Button } from "reactstrap";

export default function ImageCard(props) {
  return (
    <div className="container">
      {props.imageSide === "left" ? (
        <MDBRow className="card-row">
          {PhotoColumn(props)}
          {TextColumn(props)}
        </MDBRow>
      ) : null}
      {props.imageSide === "right" ? (
        <MDBRow className="card-row">
          {TextColumn(props)}
          {PhotoColumn(props)}
        </MDBRow>
      ) : null}
    </div>
  );
}

function PhotoColumn(props) {
  return (
    <MDBCol className="text-center" lg="6" md="6" sm="12">
      <img src={props.cardPhoto} alt="Card" className="card-image" />
    </MDBCol>
  );
}

function TextColumn(props) {
  return (
    <MDBCol lg="6" md="6" sm="12">
      <div className="card-text">
        {props.hasHeader === "true" ? <h3>{props.cardHeader}</h3> : null}
        <br />
        <p>{props.cardParagraph}</p>
        <br />
        {props.showbtn === "true" ? (
          <Button
            className="btn btn-primary"
            href={props.pathToPage}
            color="primary"
          >
            {props.btnTitle}
          </Button>
        ) : null}
      </div>
    </MDBCol>
  );
}

import React, { useState } from "react";
import config from "./../../aws-configure";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SignupImage from "./../../images/3071357.jpg";
import { signUp } from "./../../cognito";
import {
  Input,
  Label,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Container,
} from "reactstrap";
import Auth from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
const { v4: uuid } = require("uuid");

Auth.configure({
  Auth: config,
});

export default function CandidateSignup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstTimer, setFirstTimer] = useState(true);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeLoading, setVerificationCodeLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    terms: "",
  });
  const [verificationError, setVerificationError] = useState("");
  const navigate = useNavigate();

  const handleTermsChange = (e) => {
    setTerms(e.target.checked);
    setErrors((prevErrors) => ({
      ...prevErrors,
      terms: "",
    }));
  };

  function formatPhoneNumber(phoneNumber) {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return `+1${numericPhoneNumber}`;
  }

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields here
    const validationErrors = {};
    if (!firstName.trim()) {
      validationErrors.firstName = "First Name is required";
    }
    if (!lastName.trim()) {
      validationErrors.lastName = "Last Name is required";
    }
    if (!email.trim()) {
      validationErrors.email = "Email is required";
    }
    if (!phoneNumber.trim()) {
      validationErrors.phoneNumber = "Phone Number is required";
    }
    if (!password.trim()) {
      validationErrors.password = "Password is required";
    } else if (!validatePassword(password)) {
      validationErrors.password =
        "Password should include: 1 number, 1 symbol, a capital letter";
    }
    if (password.trim().length < 8) {
      validationErrors.password =
        "Password should be at least 8 characters long";
    }
    if (!confirmPassword.trim()) {
      validationErrors.confirmPassword = "Please confirm your password";
    }
    if (confirmPassword.trim() !== password.trim()) {
      validationErrors.confirmPassword = "Passwords do no match";
    }
    if (!terms) {
      validationErrors.terms = "You must agree to the terms and conditions";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      const updatedPhoneNumber = formatPhoneNumber(phoneNumber);
      const user = await signUp(
        firstName,
        lastName,
        email,
        updatedPhoneNumber,
        password,
        "Candidate",
        uuid(),
        firstTimer
      );
      setVerificationCodeSent(true);
    } catch (error) {
      if (
        error.message === "An account with the given email already exists." ||
        error.message === "User already exists"
      ) {
        setErrors({ email: "Email address already exists" });
      } else {
        console.error("Signup error:", error.message);
      }
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setVerificationCodeLoading(true);

    if (!verificationCode.trim()) {
      setVerificationError("Invalid verification code");
      return;
    } else {
      setVerificationError("");
    }

    try {
      await Auth.confirmSignUp(email, verificationCode);
      navigate("/login");
    } catch (error) {
      console.error("Verification error", error);
      setVerificationCodeLoading(false);
    }
  };

  return (
    <MDBRow className="row">
      <div style={{ height: "80vh" }}>
        <Container>
          <div className="center-vert">
            <MDBCard style={{ maxWidth: "", margin: "0 auto" }}>
              <MDBRow className="g-0">
                <MDBCol md="6" className="login-form-image">
                  <MDBCardImage
                    src={SignupImage}
                    alt="..."
                    // style={{ height: "100%" }}
                  />
                </MDBCol>
                <MDBCol md="6" className="alt-section">
                  <MDBCardBody>
                    <MDBCardTitle>Candidate Signup</MDBCardTitle>
                    <hr />
                    {!verificationCodeSent ? (
                      <Form
                        className="candidate-signup-form"
                        onSubmit={handleSubmit}
                      >
                        <MDBRow
                          style={{
                            paddingBottom:
                              errors.firstName && errors.lastName
                                ? "15px"
                                : "0px",
                          }}
                        >
                          <MDBCol sm="6">
                            <FormGroup>
                              <Input
                                id="firstName"
                                name="firstName"
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                invalid={!!errors.firstName}
                              />
                              <FormFeedback>{errors.firstName}</FormFeedback>
                            </FormGroup>
                          </MDBCol>
                          <MDBCol sm="6">
                            <FormGroup>
                              <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                invalid={!!errors.lastName}
                              />
                              <FormFeedback>{errors.lastName}</FormFeedback>
                            </FormGroup>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow
                          style={{
                            paddingBottom: errors.email ? "15px" : "0px",
                          }}
                        >
                          <MDBCol sm="12">
                            <FormGroup>
                              <Input
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                invalid={!!errors.email}
                              />
                              <FormFeedback>{errors.email}</FormFeedback>
                            </FormGroup>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow
                          style={{
                            paddingBottom: errors.phoneNumber ? "15px" : "0px",
                          }}
                        >
                          <MDBCol sm="12">
                            <FormGroup>
                              <Input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                placeholder="Phone Number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                invalid={!!errors.phoneNumber}
                              />
                              <FormFeedback>{errors.phoneNumber}</FormFeedback>
                            </FormGroup>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow
                          style={{
                            paddingBottom: errors.password ? "15px" : "0px",
                          }}
                        >
                          <MDBCol sm="12">
                            <FormGroup>
                              <Input
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                invalid={!!errors.password}
                              />
                              <FormFeedback>{errors.password}</FormFeedback>
                            </FormGroup>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow
                          style={{
                            paddingBottom: errors.confirmPassword
                              ? "15px"
                              : "0px",
                          }}
                        >
                          <MDBCol sm="12">
                            <FormGroup>
                              <Input
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                invalid={!!errors.confirmPassword}
                              />
                              <FormFeedback>
                                {errors.confirmPassword}
                              </FormFeedback>
                            </FormGroup>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="6">
                            <FormGroup>
                              <Label>Are you a first timer? </Label>
                            </FormGroup>
                          </MDBCol>
                          <MDBCol sm="6">
                            <FormGroup>
                              <Input
                                id="firstTimer"
                                type="select"
                                onChange={(e) => setFirstTimer(e.target.value)}
                              >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </Input>
                            </FormGroup>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol
                            sm="12"
                            style={{
                              paddingBottom: errors.terms ? "15px" : "0px",
                            }}
                          >
                            <FormGroup check>
                              <Label check>
                                <Input
                                  id="terms"
                                  type="checkbox"
                                  onChange={handleTermsChange}
                                  invalid={!!errors.terms}
                                />{" "}
                                Agree to{" "}
                                <a href="/terms" target={"_blank"}>
                                  Terms and Conditions
                                </a>
                              </Label>
                            </FormGroup>
                            {errors.terms && (
                              <FormFeedback>{errors.terms}</FormFeedback>
                            )}
                          </MDBCol>
                        </MDBRow>
                        <br />
                        <Button type="submit" color="primary">
                          Sign Up
                        </Button>
                        <br />
                        <MDBRow style={{ marginTop: "10px" }}>
                          <p>
                            Already have an account? <a href="/login">Login</a>
                          </p>
                        </MDBRow>
                      </Form>
                    ) : (
                      <Form
                        className="candidate-signup-form"
                        onSubmit={handleVerificationSubmit}
                      >
                        <MDBRow>
                          <p>A verification code has been sent to your email</p>
                        </MDBRow>
                        <MDBRow
                          style={{
                            paddingBottom: verificationError ? "15px" : "0px",
                          }}
                        >
                          <MDBCol sm="12">
                            <FormGroup>
                              <Input
                                id="verificationCode"
                                name="verificationCode"
                                type="text"
                                value={verificationCode}
                                onChange={(e) =>
                                  setVerificationCode(e.target.value)
                                }
                                invalid={!!verificationError}
                              />
                              <FormFeedback>{verificationError}</FormFeedback>
                            </FormGroup>
                          </MDBCol>
                        </MDBRow>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={verificationCodeLoading}
                        >
                          {verificationCodeLoading ? "Verifying..." : "Verify"}
                        </Button>
                      </Form>
                    )}
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        </Container>
      </div>
    </MDBRow>
  );
}

import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import Employers from "./pages/Employers";
import Candidates from "./pages/CandidatesPage";
import CandidateSignup from "./pages/Signup/CandidateSignup";
import EmployerSignup from "./pages/Signup/EmployerSignup";
import TermsAndConditions from "./pages/TermsAndConditions";
import ForgetPassword from "./pages/Signup/ForgetPassword";
import defaultConfig from "./aws-configure";

import "./App.css";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import MainLayout from "./pages/Layout/MainLayout";
import Login from "./pages/Signup/Login";
import PageWrapper from "./pages/Layout/PageWrapper";
import appRoutes from "./routes/appRoutes";
import axios from "axios";
import AgencySignup from "./pages/Signup/AgencySignup";

export default function App() {
  const pagesWithoutFooter = [
    "/candidate-signup",
    "/splash",
    "/app/candidate/profile",
    "/app/candidate/edit",
    "/app/employer/dashboard",
    "/app/employer/view-candidates",
    "/app/employer/settings",
    "/app/admin/dashboard",
    "/app/admin/candidates",
    "/app/agency/dashboard",
    "/app/agency/settings",
    "/candidate-signup",
    "/employer-signup",
    "/agency-signup",
    "/login",
    "/forgot-password",
  ];
  const pagesWithoutHeader = [
    "/splash",
    "/app/candidate/profile",
    "/app/candidate/edit",
    "/app/employer/dashboard",
    "/app/employer/view-candidates",
    "/app/employer/settings",
    "/app/admin/dashboard",
    "/app/admin/candidates",
    "/app/agency/dashboard",
    "/app/agency/settings",
  ];
  const [userData, setUserData] = useState(null);
  const [candidateList, setCandidateList] = useState(null);
  const [interviewList, setInterviewList] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    const storedCandidateList = localStorage.getItem("candidateList");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
    if (storedCandidateList) {
      setCandidateList(JSON.parse(storedCandidateList));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      localStorage.setItem("userData", JSON.stringify(userData));
    }
    if (candidateList) {
      localStorage.setItem("candidateList", JSON.stringify(candidateList));
    }
  }, [userData, candidateList]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    const isLoginPage = window.location.pathname === "/login";

    if (!storedUserData && !isLoginPage) {
      // navigate("/");
    }
  }, [navigate, userData]);

  const handleSetUserData = (data) => {
    setUserData(data);
  };

  const handleSetCandidateList = (data) => {
    setCandidateList(data);
  };

  const handleSetInterviewList = (data) => {
    setInterviewList(data);
  };

  const isAuthorized = (userType, allowedTypes) => {
    return allowedTypes.includes(userType);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userData && userData.role === "Employer") {
          const token = sessionStorage.getItem("userToken");
          const response = await axios.get(
            `${defaultConfig.apiUrl}getAvailableCandidates`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          // Update candidateList state with the data received from the API
          setCandidateList(response.data);
        }
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userData && userData.role === "Employer") {
          const token = sessionStorage.getItem("userToken");
          const response = await axios.get(
            `${defaultConfig.apiUrl}getInterviewsByEmployerId`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          // Update candidateList state with the data received from the API
          setInterviewList(response.data);
        }
      } catch (error) {
        console.error("Error fetching interview data:", error);
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userData && userData.role === "Agency") {
          const token = sessionStorage.getItem("userToken");
          const response = await axios.get(
            `${defaultConfig.apiUrl}getInterviewsByAgencyId`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          // Update candidateList state with the data received from the API
          setInterviewList(response.data);
        }
      } catch (error) {
        console.error("Error fetching interview data:", error);
      }
    };

    fetchData();
  }, [userData]);

  const generateRoute = (routes, userData) => {
    return routes.map((route, index) =>
      route.index ? (
        <Route
          index
          path={route.path}
          element={
            <PageWrapper state={route.state}>{route.element}</PageWrapper>
          }
          key={index}
        />
      ) : isAuthorized(userData.role, route.allowedTypes) ? (
        userData.role === "Employer" ||
        userData.role === "Admin" ||
        userData.role === "Agency" ? (
          <Route
            path={route.path}
            element={
              <PageWrapper state={route.child ? undefined : route.state}>
                {React.cloneElement(route.element, {
                  userData: userData,
                  setUserData: handleSetUserData,
                  candidateList: candidateList,
                  setCandidateList: handleSetCandidateList,
                  interviewList: interviewList,
                  setInterviewList: handleSetInterviewList,
                })}
              </PageWrapper>
            }
            key={index}
          >
            {route.child && generateRoute(route.child, userData, candidateList)}
          </Route>
        ) : (
          <Route
            path={route.path}
            element={
              <PageWrapper state={route.child ? undefined : route.state}>
                {React.cloneElement(route.element, {
                  userData: userData,
                  setUserData: handleSetUserData, // Pass setUserData function
                })}
              </PageWrapper>
            }
            key={index}
          >
            {route.child && generateRoute(route.child, userData)}
          </Route>
        )
      ) : null
    );
  };

  const generateAuthorizedRoutes = (user) => {
    if (!userData) return [];

    return generateRoute(appRoutes, userData);
  };

  const authorizedRoutes = generateAuthorizedRoutes(userData);

  return (
    <div>
      {!pagesWithoutHeader.includes(window.location.pathname) && <NavBar />}
      <Routes>
        {/* <Route index Component={ComingSoon} /> */}
        <Route index Component={Home} />
        <Route path="/about-us" Component={AboutUs} />
        <Route path="/candidates" Component={Candidates} />
        <Route path="/employers" Component={Employers} />
        {/* <Route path="/contact-us" Component={ContactUs} /> */}
        <Route path="/candidate-signup" Component={CandidateSignup} />
        <Route path="/employer-signup" Component={EmployerSignup} />
        <Route path="/agency-signup" Component={AgencySignup} />
        {/* <Route path="/splash" Component={SplashScreen} /> */}
        <Route path="/terms" Component={TermsAndConditions} />
        <Route path="/forgot-password" Component={ForgetPassword} />
        <Route
          path="/login"
          element={<Login setUserData={handleSetUserData} />}
        ></Route>
        <Route path="/app" element={<MainLayout userData={userData} />}>
          {authorizedRoutes}
        </Route>
      </Routes>
      {!pagesWithoutFooter.includes(window.location.pathname) && <Footer />}
    </div>
  );
}

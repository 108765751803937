import React, { useState } from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SignupImage from "./../../images/2150793919.jpg";
import {
  Input,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Container,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../../cognito"; // Assuming you have a function for initiating the password reset
import axios from "axios";
import defaultConfig from "../../aws-configure";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validationErrors = {};
    if (!email.trim()) {
      validationErrors.email = "Email is required";
    }
    if (!password.trim()) {
      validationErrors.password = "Password is required";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setLoading(false);
      return;
    }
    try {
      const user = await signIn(email, password);

      const userDataResponse = await axios.get(
        `${defaultConfig.apiUrl}getUserById`,
        {
          headers: {
            Authorization: user.idToken.jwtToken,
          },
        }
      );
      sessionStorage.setItem("user", JSON.stringify(userDataResponse.data));
      localStorage.setItem("user", JSON.stringify(userDataResponse.data));
      sessionStorage.setItem("userToken", user.idToken.jwtToken);
      props.setUserData(userDataResponse.data);
      if (userDataResponse.data.role === "Candidate") {
        navigate("/app/candidate/profile");
      } else if (userDataResponse.data.role === "Employer") {
        navigate("/app/employer/dashboard");
      } else if (userDataResponse.data.role === "Admin") {
        navigate("/app/admin/dashboard");
      } else if (userDataResponse.data.role === "Agency") {
        navigate("/app/agency/dashboard");
      }
    } catch (error) {
      setErrors({ generic: "Invalid email or password" });
      console.error("SignIn error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MDBRow className="row">
      <div style={{ height: "80vh" }}>
        {/* <div className="container-vert"> */}
        <Container>
          <div className="center-vert">
            <MDBCard style={{ maxWidth: "", margin: "0 auto" }}>
              <MDBRow className="g-0">
                <MDBCol md="6" className="login-form-image">
                  <MDBCardImage
                    src={SignupImage}
                    alt="..."
                    // style={{ height: "100%" }}
                  />
                </MDBCol>
                <MDBCol md="6" className="alt-section login-form">
                  <MDBCardBody>
                    <MDBCardTitle>Welcome</MDBCardTitle>
                    <p>Login to your portal</p>
                    <hr />
                    <Form
                      className="candidate-signup-form"
                      onSubmit={handleSubmit}
                    >
                      {errors.generic && (
                        <div className="text-danger mb-3">{errors.generic}</div>
                      )}
                      <MDBRow
                        style={{
                          paddingBottom: errors.email ? "15px" : "0px",
                        }}
                      >
                        <MDBCol sm="12">
                          <FormGroup>
                            <Input
                              id="email"
                              name="email"
                              type="email"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              invalid={!!errors.email}
                            />
                            <FormFeedback>{errors.email}</FormFeedback>
                          </FormGroup>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow
                        style={{
                          paddingBottom: errors.password ? "15px" : "0px",
                        }}
                      >
                        <MDBCol sm="12">
                          <FormGroup>
                            <Input
                              id="password"
                              name="password"
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              invalid={!!errors.password}
                            />
                            <FormFeedback>{errors.password}</FormFeedback>
                          </FormGroup>
                          <p className="mt-3">
                            <Link to="/forgot-password">Forgot Password?</Link>
                          </p>
                        </MDBCol>
                      </MDBRow>
                      {/* <MDBRow>
                      <p className="mt-3">
                        Don't have an account? <Link to="/signup">Sign up</Link>
                      </p>
                    </MDBRow> */}
                      <Button type="submit" color="primary" disabled={loading}>
                        {loading ? "Loading..." : "Login"}{" "}
                      </Button>
                    </Form>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        </Container>
      </div>
      {/* </div>  */}
    </MDBRow>
  );
}

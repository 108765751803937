const devConfig = {
  region: "us-east-1",
  userPoolId: "us-east-1_pymjdDI0a",
  userPoolWebClientId: "4d0kjcjf6prhps5hdgqma7bkes",
  apiUrl: "https://oi7gkhbw0m.execute-api.us-east-1.amazonaws.com/dev/",
  paypalClientId:
    "AV7I18o5y8mB2Pp9mXSMC-BVP0PAN71DOgRYH6gDn5yhES-_PKMV5kba2Jtrxa5Lam8yIDvrerK8fN-v",
};

const prodConfig = {
  region: "us-east-2",
  userPoolId: "us-east-2_X9VT5YtLY",
  userPoolWebClientId: "7lhes99fk3u7k3v1c6jiik8ece",
  apiUrl: "https://rgkmcc0se4.execute-api.us-east-2.amazonaws.com/prod/",
  paypalClientId:
    "AfKI1LCxYbuTIKgsGLMRoDH8XNvzM069qopYROtZqmwR_Yif4ZBPk2fmoIFIPyFz8_x75_mZapkKQln8",
};

let config = process.env.REACT_APP_APP_ENV === "prod" ? prodConfig : devConfig;

const defaultConfig = config;

export default defaultConfig;

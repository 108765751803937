import React, { useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import ForgotPasswordImage from "./../../images/2150793919.jpg";
import { Input, FormGroup, FormFeedback, Form, Container } from "reactstrap";
import { confirmForgotPassword, initiateForgotPassword } from "../../cognito";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [resetCodeSent, setResetCodeSent] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!newPassword.trim()) {
      validationErrors.newPassword = "Password is required";
    } else if (!validatePassword(newPassword)) {
      validationErrors.newPassword =
        "Password should include: 1 number, 1 symbol, a capital letter";
    }
    if (newPassword.trim().length < 8) {
      validationErrors.newPassword =
        "Password should be at least 8 characters long";
    }
    if (!confirmNewPassword.trim()) {
      validationErrors.confirmNewPassword = "Please confirm your password";
    }
    if (confirmNewPassword.trim() !== newPassword.trim()) {
      validationErrors.confirmNewPassword = "Passwords do no match";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    setLoading(true);
    setErrors({});

    try {
      await confirmForgotPassword(email, verificationCode, newPassword);
      setMessage("Password reset successfully.");
      setResetCodeSent(false);
      setEmail("");
      setVerificationCode("");
      setNewPassword("");
      setConfirmNewPassword("");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Forgot password error:", error);
      setMessage("Error occurred while resetting password.");

      // Handle invalid verification code error
      if (
        error.message ===
        "Invalid verification code provided, please try again."
      ) {
        setErrors({ verificationCode: "Invalid verification code" });
      }
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleRequestCode = async () => {
    setLoading(true);
    try {
      await initiateForgotPassword(email);
      setLoading(false);
      setMessage("Password reset code sent to your email.");
      setResetCodeSent(true);
      setErrors({}); // Clear any previous errors
    } catch (error) {
      console.error("Forgot password error:", error);
      setMessage("Error occurred while sending password reset code.");
    }
  };

  return (
    <MDBRow className="row">
      <div style={{ height: "80vh" }}>
        <Container>
          <div className="center-vert">
            <MDBCard style={{ maxWidth: "", margin: "0 auto" }}>
              <MDBRow className="g-0">
                <MDBCol md="6" className="forgot-password-image">
                  <MDBCardImage
                    src={ForgotPasswordImage}
                    alt="Forgot Password"
                    // style={{ height: "100%" }}
                  />
                </MDBCol>
                {resetCodeSent ? (
                  <MDBCol md="6" className="alt-section forgot-password-form">
                    <MDBCardBody>
                      <MDBCardTitle>Reset Password</MDBCardTitle>
                      <p>
                        Enter the verification code sent to your email along
                        with your new password
                      </p>
                      <hr />
                      {message && (
                        <div className={`text-danger mb-3`}>{message}</div>
                      )}
                      <Form
                        className="candidate-signup-form"
                        onSubmit={handleSubmit}
                      >
                        <FormGroup>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup
                          style={{
                            paddingBottom: errors.verificationCode
                              ? "15px"
                              : "0px",
                          }}
                        >
                          <Input
                            id="verificationCode"
                            name="verificationCode"
                            type="text"
                            placeholder="Verification Code"
                            value={verificationCode}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                            invalid={!!errors.verificationCode}
                          />
                          <FormFeedback>{errors.verificationCode}</FormFeedback>
                        </FormGroup>
                        <FormGroup
                          style={{
                            paddingBottom: errors.newPassword ? "15px" : "0px",
                          }}
                        >
                          <Input
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            invalid={!!errors.newPassword}
                          />
                          <FormFeedback>{errors.newPassword}</FormFeedback>
                        </FormGroup>
                        <FormGroup
                          style={{
                            paddingBottom: errors.confirmNewPassword
                              ? "15px"
                              : "0px",
                          }}
                        >
                          <Input
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmNewPassword}
                            onChange={(e) =>
                              setConfirmNewPassword(e.target.value)
                            }
                            invalid={!!errors.confirmNewPassword}
                          />
                          <FormFeedback>
                            {errors.confirmNewPassword}
                          </FormFeedback>
                        </FormGroup>
                        <MDBBtn
                          type="submit"
                          color="primary"
                          disabled={loading}
                        >
                          {loading ? "Resetting..." : "Reset Password"}
                        </MDBBtn>
                      </Form>
                    </MDBCardBody>
                  </MDBCol>
                ) : (
                  <MDBCol md="6" className="alt-section forgot-password-form">
                    <MDBCardBody>
                      <MDBCardTitle>Forgot Password</MDBCardTitle>
                      <p>Enter your email address to reset your password.</p>
                      <hr />
                      {message && (
                        <div className={`text-success mb-3`}>{message}</div>
                      )}
                      <Form className="candidate-signup-form">
                        <FormGroup>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                        <MDBBtn
                          type="button"
                          color="primary"
                          onClick={handleRequestCode}
                          disabled={loading}
                        >
                          {loading ? "Sending..." : "Request Code"}
                        </MDBBtn>
                      </Form>
                    </MDBCardBody>
                  </MDBCol>
                )}
              </MDBRow>
            </MDBCard>
          </div>
        </Container>
      </div>
    </MDBRow>
  );
}

import React from "react";
import { Box, Toolbar } from "@mui/material";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import sizeConfigs from "./../../configs/sizeConfigs";
import colorConfigs from "./../../configs/colorConfig";
import { Outlet } from "react-router-dom";

export default function MainLayout({ userData }) {
  return (
    <Box sx={{ display: "flex" }}>
      <Topbar />
      <Box
        component="nav"
        sx={{ width: sizeConfigs.sidebar.width, flexShrink: 0 }}
      >
        <Sidebar userData={userData} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import CandidateCard from "../components/CandidateCard";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import jobList from "./../jobList.json";

export default function Candidates({ candidateList, setCandidateList }) {
  const [userData, setUserData] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedFirstTimer, setSelectedFirstTimer] = useState("");
  const [genderDropdownOpen, setGenderDropdownOpen] = useState(false);
  const [positionDropdownOpen, setPositionDropdownOpen] = useState(false);
  const [firstTimerDropdownOpen, setFirstTimerDropdownOpen] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      localStorage.setItem("userData", JSON.stringify(userData));
    }
  }, [userData]);

  const handleGenderChange = (value) => {
    setSelectedGender(value);
  };

  const handlePositionChange = (value) => {
    setSelectedPosition(value);
  };

  const handleFirstTimerChange = (value) => {
    setSelectedFirstTimer(value);
  };
  const filteredCandidateList = candidateList
    ? candidateList.filter(
        (candidate) =>
          candidate.employmentStatus === "Available" &&
          (selectedGender === "" ||
            candidate.gender === selectedGender ||
            selectedGender === "All Genders") &&
          (selectedPosition === "" ||
            candidate.positions?.primary === selectedPosition ||
            candidate.positions?.secondary === selectedPosition ||
            selectedPosition === "All Positions") &&
          (selectedFirstTimer === "" ||
            candidate.firstTimer.toString() === selectedFirstTimer ||
            selectedFirstTimer === "All")
      )
    : [];

  const genderOptions = ["All Genders", "Male", "Female"];
  const sortedJobList = [...jobList].sort();
  const positionOptions = sortedJobList;

  const firstTimerOptions = ["All", "true", "false"];

  const handleStatusChange = async (candidateId, newStatus) => {
    try {
      const updatedCandidateList = candidateList.map((candidate) =>
        candidate.id === candidateId
          ? { ...candidate, employmentStatus: newStatus }
          : candidate
      );
      setCandidateList(updatedCandidateList);
    } catch (error) {
      console.error("Error updating candidate status:", error);
    }
  };

  return (
    <section>
      <MDBContainer>
        <MDBRow>
          <h4>View Available Candidate</h4>
          <p>View a list of all our available candidates</p>
        </MDBRow>
        <MDBRow className="mb-3">
          <MDBCol size="auto">
            <Dropdown
              isOpen={genderDropdownOpen}
              toggle={() => setGenderDropdownOpen(!genderDropdownOpen)}
            >
              <DropdownToggle caret>
                {selectedGender ? selectedGender : "All Genders"}
              </DropdownToggle>
              <DropdownMenu>
                {genderOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleGenderChange(option)}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </MDBCol>
          <MDBCol size="auto">
            <Dropdown
              isOpen={positionDropdownOpen}
              toggle={() => setPositionDropdownOpen(!positionDropdownOpen)}
            >
              <DropdownToggle caret>
                {selectedPosition ? selectedPosition : "All Positions"}
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
                {" "}
                {/* Limiting height and enabling scrolling */}
                {positionOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handlePositionChange(option)}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </MDBCol>
          <MDBCol size="auto">
            <Dropdown
              isOpen={firstTimerDropdownOpen}
              toggle={() => setFirstTimerDropdownOpen(!firstTimerDropdownOpen)}
            >
              <DropdownToggle caret>
                {selectedFirstTimer !== ""
                  ? selectedFirstTimer === "true"
                    ? "First Timer"
                    : "Not First Timer"
                  : "All"}
              </DropdownToggle>
              <DropdownMenu>
                {firstTimerOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleFirstTimerChange(option)}
                  >
                    {option === "true"
                      ? "First Timer"
                      : option === "false"
                      ? "Not First Timer"
                      : option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </MDBCol>
        </MDBRow>
        <div className="container">
          {userData && userData.status === "Enabled" ? (
            <MDBRow className="d-flex flex-column flex-md-row">
              {filteredCandidateList.map((candidate, index) => (
                <MDBCol className="col-md-3" key={index}>
                  <CandidateCard
                    candidateData={candidate}
                    setCandidateData={setCandidateData}
                    candidateList={candidateList}
                    setCandidateList={setCandidateList}
                    onUpdateStatus={handleStatusChange}
                    selectedPosition={selectedPosition}
                  />
                </MDBCol>
              ))}
            </MDBRow>
          ) : (
            <MDBRow>
              <p>
                Please wait until your account has been verified by an admin
                before viewing the list of candidates.
              </p>
            </MDBRow>
          )}
        </div>
      </MDBContainer>
    </section>
  );
}

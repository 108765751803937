// cognito.js
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import defaultConfig from "./aws-configure";

const poolData = {
  UserPoolId: defaultConfig.userPoolId,
  ClientId: defaultConfig.userPoolWebClientId,
};

const userPool = new CognitoUserPool(poolData);

export const signUp = (
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  customRole,
  customIdentifier,
  firstTimer
) => {
  const attributeList = [
    new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
    new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
    new CognitoUserAttribute({ Name: "email", Value: email }),
    new CognitoUserAttribute({ Name: "phone_number", Value: phoneNumber }),
    new CognitoUserAttribute({ Name: "custom:role", Value: customRole }),
    new CognitoUserAttribute({
      Name: "custom:identifier",
      Value: customIdentifier,
    }),
    new CognitoUserAttribute({
      Name: "custom:firstTimer",
      Value: String(firstTimer),
    }),
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result.user);
    });
  });
};

export const employerSignUp = (
  firstName,
  lastName,
  email,
  companyName,
  phoneNumber,
  password,
  customRole,
  customIdentifier
) => {
  const attributeList = [
    new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
    new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
    new CognitoUserAttribute({ Name: "email", Value: email }),
    new CognitoUserAttribute({ Name: "phone_number", Value: phoneNumber }),
    new CognitoUserAttribute({ Name: "custom:role", Value: customRole }),
    new CognitoUserAttribute({
      Name: "custom:identifier",
      Value: customIdentifier,
    }),
    new CognitoUserAttribute({
      Name: "custom:companyName",
      Value: companyName,
    }),
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result.user);
    });
  });
};

export const signIn = (username, password) => {
  const authenticationData = {
    Username: username,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => resolve(session),
      onFailure: (err) => reject(err),
    });
  });
};

export const initiateForgotPassword = async (email) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: () => {
        // If the request is successful, it means the password reset code has been sent to the user's email
        resolve(true);
      },
      onFailure: (error) => {
        console.error("Error initiating forgot password:", error);
        reject(error);
      },
    });
  });
};

export const confirmForgotPassword = (email, verificationCode, newPassword) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (error) => {
        reject(error);
      },
    });
  });
};

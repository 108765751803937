import React, { useState } from "react";
import Hero from "../components/Hero";
import ImageCard from "../components/ImageCard";
import {
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
} from "reactstrap";
import CandidateCard from "./../images/CandidateCard.png";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

export default function Candidates() {
  const hero = {
    image: require("../images/2148677810.jpg"),
    headerText: "Candidates",
    subText:
      "Enhance your competitive advantage in securing selection by our esteemed top-tier employers.",
  };

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <div>
        <Hero
          image={hero.image}
          headerText={hero.headerText}
          subText={hero.subText}
        />
      </div>
      <div className="blue-background">
        <p className="text-center">
          The H2B Program is not dying or difficult, not even close. It is more
          poignant that is has been in years! The resilient program has seen its
          most successful this last 2022 in the 60th year since its inception.
        </p>
      </div>
      <div className="container page-body" style={{ marginBottom: "30px" }}>
        <MDBRow className="text-center container">
          <h3>Choose the Perfect Plan to Boost Your Career!</h3>
          <p>
            We offer three comprehensive plans tailored to meet your needs,
            whether you're an H2B candidate or looking for opportunities across
            the globe. Select the plan that best fits your career goals and gain
            access to a wide range of employers and exclusive features designed
            to enhance your visibility and job prospects.
          </p>
        </MDBRow>
        <MDBRow className="container justify-content-center">
          <MDBCol xs="12" sm="6" md="4" className="d-flex align-items-stretch">
            <Card className="my-2 w-100">
              <CardHeader
                className="text-center"
                style={{ backgroundColor: "#2553AD", color: "#fff" }}
              >
                <h3>H2B Basic</h3>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <CardTitle tag="h5" className="text-center">
                  $0 per year (free)
                </CardTitle>
                <hr />
                <br />
                <ul>
                  <li>Accessible to H2B employers only</li>
                </ul>
              </CardBody>
              <CardFooter className="d-flex justify-content-center">
                <Button
                  className="col-6"
                  color="primary"
                  href="/candidate-signup"
                >
                  SignUp
                </Button>
              </CardFooter>
            </Card>
          </MDBCol>
          <MDBCol xs="12" sm="6" md="4" className="d-flex align-items-stretch">
            <Card className="my-2 w-100">
              <CardHeader
                className="text-center"
                style={{ backgroundColor: "#2553AD", color: "#fff" }}
              >
                <h3>360 Standard</h3>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <CardTitle tag="h5" className="text-center">
                  $74.99 per year
                </CardTitle>
                <hr />
                <br />
                <ul>
                  <li>
                    Accessible to H2B employers and other US Visa employers (J1
                    Work and Travel, Intern, Q1, L1B, EB3, Teaching, Au Pair)
                  </li>
                  <li>
                    Accessible to employers in every country: US, Canada, UK,
                    Luxembourg, Cayman, Dubai, Australia, and Cruise Ships
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="d-flex justify-content-center">
                <Button
                  className="col-6"
                  color="primary"
                  href="/candidate-signup"
                >
                  SignUp
                </Button>
              </CardFooter>
            </Card>
          </MDBCol>
          <MDBCol xs="12" sm="6" md="4" className="d-flex align-items-stretch">
            <Card className="my-2 w-100">
              <CardHeader
                className="text-center"
                style={{ backgroundColor: "#2553AD", color: "#fff" }}
              >
                <h3>Premium Boost</h3>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <CardTitle tag="h5" className="text-center">
                  $145 for 3 years
                </CardTitle>
                <hr />
                <br />
                <ul>
                  <li>Boosts your profile for higher visibility</li>
                  <li>
                    Includes everything in the H2B Basic and 360 Standard
                    packages
                  </li>
                  <li>
                    Profile stays on the site for 3 years, regardless of hiring
                    status
                  </li>
                  <li>
                    Receive interview requests from new employers from all
                    countries for in-country extensions or international moves
                  </li>
                  <li>Highlighted profile for easy spotting by employers</li>
                </ul>
              </CardBody>
              <CardFooter className="d-flex justify-content-center">
                <Button
                  className="col-6"
                  color="primary"
                  href="/candidate-signup"
                >
                  SignUp
                </Button>
              </CardFooter>
            </Card>
          </MDBCol>
        </MDBRow>
      </div>

      <div className="page-body alt-section">
        <ImageCard
          imageSide="right"
          hasHeader="true"
          cardHeader="How To Create A Profile"
          cardParagraph="Just put your best foot forward, In 5 easy steps you can
          complete the H2B Direct question sheet so your profile can
          look like the one shown in the example below. Upload a
          professional profile picture showing your head down to your
          shoulders and no further than your torso. Use a clean, clear
          background like a wall or door for your photo. Accurate
          information must be used for your experience and employers
          may call previous employers and references on your profiles."
          showbtn="false"
          cardPhoto={CandidateCard}
        />
      </div>
      <div className="blue-background-section" style={{}}>
        <div className="container">
          <div>
            <div>
              <h3>Frequently asked Questions</h3>
              <br />
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    Who Can Create A Profile?
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    <ul>
                      <li>First timers known as out-of-country applicants</li>
                      <li>
                        Returning H2B Applicants (have traveled on an H2B visa
                        within the last 3 years)
                      </li>
                      <li>
                        In-country H2B Applicants looking to extend their H2B
                        Visas
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">
                    Having troubles?
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    We are here to help! H2B can be quite stressful finding
                    agencies or employers by yourself. It can sometimes take
                    several years to secure a successful placement in the US.
                    H2B Direct, reaches candidates from over 20 countries, we
                    house their face-featured profiles on our site so that
                    employers with H2B openings can find them easily. You can
                    then communicate directly with your employers for quick and
                    easy processing.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">
                    Waiting For An Interview Request
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    Employers will be reviewing Profiles to find matches for
                    their job openings. You will be alerted via email when an
                    employer had chosen you for an interview, you have the right
                    to accept or decline an offer but note that every offer is
                    an opportunity that should not be wasted. Interviews can be
                    had via phone call or video calls (Zoom/Teams) you will be
                    notified on the site and via email so be sure to check your
                    emails ofter. Once an H2B Season is closed, new employers
                    from various visa categories and countries will also be
                    perusing the platform looking for workers from countries
                    like Australia, New Zealand, UK, Dubai, C1D
                    Crew(Cruise-lines) and Canada so always be alert.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
              <br />
              <p>
                H2B resources are all listed on our platform for better
                connectivity and understanding. We are always improving our
                platform for ease of use and added value to you our members.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ padding: "20px" }}>
        <div className="container text-center">
          <h5>Get Started Today!</h5>
          <Button color="primary" href="/candidate-signup">
            Sign Up!
          </Button>
        </div>
      </div> */}
    </div>
  );
}

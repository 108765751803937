import React from "react";
import { MDBRow, MDBContainer } from "mdb-react-ui-kit";

function TermsAndConditions() {
  return (
    <div>
      <MDBContainer style={{ padding: "5% 10%" }}>
        <MDBRow>
          <h1>Our Terms of Service</h1>
          <p>Effective April 1, 2024</p>
          <p>
            <a href="/">Back Home</a>
          </p>
        </MDBRow>
        <MDBRow>
          <p>
            Welcome to <a href="/home">H2BDirect.net</a> ("H2BDirect.net," "we,"
            or "our"). By using our website <a href="/home">H2BDirect.net</a>{" "}
            and its related services, products, news, and reviews, you agree to
            abide by these terms of Services ("Terms of Services," "TOS "). Your
            acceptance of these Terms also applies when you create an account,
            visit the website as a guest, or log in to the website. Please be
            aware that certain services or features on our website may have
            additional or separate terms that govern your interactions with
            them. If there is a conflict between these Terms and any additional
            terms, the additional terms will take precedence.
          </p>
          <p>
            We reserve the right to update any content, and the Terms at our
            discretion, without prior notice. It is your responsibility to stay
            informed about any updates or changes to the Terms.
          </p>
          <p>
            When you engage in reviewing our services or commenting on articles,
            you may be asked to provide your name and location along with your
            question or comment. However, please note that we do not store any
            other personal information in the forum or comment archive. By using
            the website, you consent to the collection and utilization of your
            information by <a href="/home">H2BDirect.net</a>.
          </p>
          <p>
            Please read these Terms carefully before using the website. By
            accessing or using the website, you agree to be bound by these
            Terms. If you do not agree to these Terms, you may not access or use
            the Website.
          </p>
        </MDBRow>
        <MDBRow>
          <h3>1. SERVICES PROVIDED</h3>
          <p>
            We are committed to offering a comprehensive array of services aimed
            at facilitating the connection between international job seekers and
            employers in the United States, primarily focusing on the H-2B visa
            category. Our platform acts as a crucial intermediary, enabling
            seamless communication and interaction between job seekers and
            employers.
          </p>
        </MDBRow>
        <MDBRow>
          <h5>1.1 VISA CATEGORIES WE ACCOMMODATE</h5>
          <MDBContainer>
            <MDBRow>
              <MDBContainer>
                <ol type="A">
                  <li>
                    <strong>H-2B Visa</strong>: Our core focus is on the H-2B
                    Visa, assisting
                  </li>
                  <li>Other U.S. Visa Categories:</li>
                  <ol>
                    <li>
                      <strong>J1 Visa</strong>: For exchange visitors, including
                      students, interns, and trainees.
                    </li>
                    <li>
                      <strong>Q1 Visa</strong>: For participants in cultural
                      exchange programs.
                    </li>
                    <li>
                      <strong>H1B Visa</strong>: For professionals in
                      specialized occupations.
                    </li>
                    <li>
                      <strong>L1B Visa</strong>: For intra-company transferees
                      with specialized knowledge.
                    </li>
                    <li>
                      <strong>EB3 Visa</strong>: For skilled workers,
                      professionals, and other workers.
                    </li>
                    <li>
                      <strong>C1D Visa</strong>: For crew members working on
                      international flights or sea vessels.
                    </li>
                  </ol>
                  <li>International Visa Categories:</li>
                  <ol>
                    <li>
                      <strong>UK Tier 2 & 3</strong>: For skilled workers and
                      temporary Labor migrants.
                    </li>
                    <li>
                      <strong>Canadian Foreign Worker Program</strong>: For
                      temporary foreign workers in Canada.
                    </li>
                    <li>
                      <strong>Visas from Other Countries</strong>: Including
                      Canada, the UK, Luxembourg, Dubai, New Zealand, Australia,
                      and Cruise-lines.
                    </li>
                  </ol>
                </ol>
              </MDBContainer>
            </MDBRow>
            <MDBRow>
              <h5>1.2 OUR SPECIFIC SERVICES INCLUDE</h5>
              <MDBContainer>
                <ol type="A">
                  <li>
                    <strong>Visa Category Accommodation</strong>: We support a
                    wide range of visa categories beyond H-2B, providing a
                    broader platform for candidates from different countries and
                    visa types to connect with employers.
                  </li>
                  <li>
                    <strong>Candidate Profiles</strong>: Candidates can create
                    and manage profiles on our platform. These profiles are
                    visible to employers seeking workers for various visa
                    categories, enhancing their chances of being selected for
                    interviews.
                  </li>
                  <li>
                    <strong>Employer Access</strong>: Employers from multiple
                    countries and visa categories will regularly use our
                    platform to scout for candidates. This includes recruiters,
                    travel agents, HR personnel, immigration lawyers, management
                    companies, and housing managers from over 80 countries.
                  </li>
                  <li>
                    <strong>Global Recruitment</strong>: Our platform
                    facilitates the recruitment of international candidates
                    across diverse visa categories, ensuring a wider range of
                    opportunities and reducing the likelihood of candidates
                    being overlooked.
                  </li>
                  <li>
                    <strong>Profile Visibility</strong>: Candidates simply need
                    to create and maintain their profiles. Employers and
                    recruiters will scout profiles regularly to find suitable
                    candidates for their needs, making the process efficient for
                    both parties.
                  </li>
                  <li>
                    <strong>Facilitated Hiring and Relocation</strong>: We aim
                    to streamline the hiring, visa processing, and relocation
                    processes for both candidates and employers, providing a
                    smooth and effective recruitment experience.
                  </li>
                  <li>
                    <strong>Regular Updates</strong>: Employers will frequently
                    come onto our platform to search for candidates. Candidates
                    should keep their profiles updated to increase their
                    visibility and chances of being chosen for interviews.
                  </li>
                </ol>
              </MDBContainer>
            </MDBRow>
            <MDBRow>
              <h5>1.3 PROFILE CREATION AND MANAGEMENT</h5>
              <p>
                Job seekers can leverage our user-friendly interface to create
                detailed profiles showcasing their skills, qualifications, and
                experiences. These profiles serve as dynamic representations of
                their professional identities, allowing them to stand out to
                potential employers.
              </p>
            </MDBRow>
            <MDBRow>
              <h5>1.4 JOB SEARCH AND MATCHING</h5>
              <p>
                Our advanced algorithms meticulously match job seekers with
                relevant employment opportunities based on their preferences,
                expertise, and location. Through personalized recommendations,
                we streamline the job search process, maximizing the chances of
                finding the perfect match for both parties.
              </p>
            </MDBRow>
            <MDBRow>
              <h5>1.5 EMPLOYER RECRUITMENT TOOLS</h5>
              <p>
                For employers, <a href="/home">H2BDirect.net</a> offers a suite
                of recruitment tools designed to simplify the hiring process.
                From posting job listings to accessing a pool of pre-screened
                candidates, our platform empowers employers to efficiently
                identify and engage with qualified talent.
              </p>
            </MDBRow>
            <MDBRow>
              <h5>1.6 VISA GUIDANCE AND SUPPORT</h5>
              <p>
                Navigating the intricacies of visa categories can be
                challenging. To alleviate this, we provide comprehensive visa
                guidance and support to both job seekers and employers. Our
                knowledgeable team offers expert advice, ensuring compliance
                with relevant regulations and facilitating a smooth application
                process.
              </p>
            </MDBRow>
            <MDBRow>
              <h5>1.7 COMMUNITY ENGAGEMENT</h5>
              <p>
                Beyond job listings and profiles, H2BDirect.net fosters a
                vibrant community where job seekers and employers can interact,
                share insights, and build professional relationships. Through
                forums, discussion boards, and networking events, we cultivate a
                collaborative environment conducive to success.
              </p>
            </MDBRow>
            <MDBRow>
              <h5>1.8 CONTINUOUS IMPROVEMENT AND INNOVATION</h5>
              <p>
                At H2BDirect.net, we are dedicated to continuous improvement and
                innovation. We regularly solicit feedback from our users to
                enhance our platform's features, functionality, and user
                experience. By staying at the forefront of technological
                advancements, we strive to provide best-in-class services that
                meet the evolving needs of our community.
              </p>
              <p>
                We offer a multifaceted suite of services designed to empower
                both job seekers and employers in the H-2B visa category. From
                profile creation and job matching to visa support and community
                engagement, our platform serves as a catalyst for meaningful
                connections and opportunities in the international job market.
              </p>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
        <MDBRow>
          <h3>2. USER REGISTRATION</h3>
          <p>
            Our user registration process is designed to be seamless, intuitive,
            and tailored to meet the specific needs of job seekers and employers
            alike. Whether you're embarking on a new career journey or seeking
            to expand your workforce, our platform offers a straightforward
            registration process that unlocks a world of opportunities.
          </p>
        </MDBRow>

        <MDBRow>
          <h5>2.1 JOB SEEKER REGISTRATION:</h5>
          <p>
            Job seekers may register on the Website by providing accurate and
            complete information, including personal details and qualifications.
            By registering, job seekers agree to pay an annual fee of $74.99,
            which covers the cost of maintaining their profiles on the Website,
            website maintenance, and associated administrative expenses.
          </p>

          <MDBContainer>
            <MDBRow>
              <h6>(A) CREATING YOUR PROFILE:</h6>
              <p>
                Job seekers are invited to embark on their professional journey
                by creating a comprehensive profile on H2BDirect.net. Our
                intuitive interface guides users through the process of
                inputting essential information such as personal details,
                professional qualifications, and employment preferences. With
                the ability to upload resumes, showcase portfolios, and
                highlight relevant experiences, job seekers can craft compelling
                profiles that resonate with potential employers.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(B) CUSTOMIZED JOB PREFERENCES:</h6>
              <p>
                Understanding that every job seeker is unique, we empower users
                to customize their job preferences to align with their career
                aspirations. Whether you're seeking seasonal employment
                opportunities or year-round placements, our platform caters to a
                diverse range of preferences and requirements. From location
                preferences to industry-specific criteria, job seekers have the
                flexibility to tailor their search criteria to suit their
                individual needs.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(C) ACCESS TO EXCLUSIVE RESOURCES:</h6>
              <p>
                Upon completing registration, job seekers gain access to a
                wealth of exclusive resources aimed at enhancing their job
                search experience. From educational materials on visa
                regulations to tips for acing interviews, our platform equips
                users with the knowledge and tools needed to succeed in the
                competitive job market.
              </p>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          <h5>2.2 EMPLOYER REGISTRATION:</h5>
          <MDBContainer>
            <MDBRow>
              <h6>(A) STREAMLINED HIRING PROCESS:</h6>
              <p>
                Employers seeking to leverage the talent pool available through
                H2BDirect.net can register effortlessly to gain access to our
                platform's robust suite of recruitment tools. Our streamlined
                registration process allows employers to create company
                profiles, post job listings, and engage with qualified
                candidates seamlessly.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(B) TARGETED CANDIDATE SEARCH:</h6>
              <p>
                With access to our extensive database of job seekers, employers
                can conduct targeted candidate searches based on specific
                criteria such as skills, experience levels, and visa
                requirements. Our advanced search filters enable employers to
                narrow down their candidate pool, ensuring they connect with the
                most suitable candidates for their staffing needs.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(C) COMPLIANCE ASSISTANCE:</h6>
              <p>
                Navigating the complexities of the H-2B visa category can be
                daunting for employers. To alleviate this challenge, our
                platform provides comprehensive compliance assistance and
                support throughout the hiring process. From understanding
                regulatory requirements to facilitating visa documentation, we
                empower employers to navigate the process with confidence and
                ease.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(D) NETWORKING OPPORTUNITIES:</h6>
              <p>
                In addition to recruitment tools, H2BDirect.net offers employers
                access to networking opportunities and industry insights.
                Through participation in forums, webinars, and networking
                events, employers can connect with peers, share best practices,
                and stay abreast of industry trends.
              </p>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
        <MDBRow>
          <h3>3. FEES AND PAYMENTS</h3>
          <p>
            At H2BDirect.net, transparency and fairness are at the core of our
            fee structure and payment policies. We strive to provide a clear and
            straightforward framework that ensures equitable treatment for both
            job seekers and employers participating in our platform.
          </p>
        </MDBRow>

        <MDBRow>
          <h5>3.1 JOB SEEKER FEE:</h5>
          <MDBContainer>
            <MDBRow>
              <h6>(A) SUBSCRIPTION COSTS:</h6>
              <p>
                The annual subscription fee for our full-service platform is
                $74.99. This fee is applicable to users applying under the
                following visa categories: J1, Q1, C1D, H1B, L1B, Tier 2, Tier
                3, EB3, and other similar visa types.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(B) H2B VISA APPLICANTS:</h6>
              <p>
                Candidates who opt for the H2B Visa package will receive our
                services free of charge. In the event that any costs are
                inadvertently incurred by H2B applicants on this site, such
                costs will be reimbursed by the employer, in accordance with
                U.S. law, which mandates that H2B Visa recruitment costs must be
                borne by the employer. It is important to note that our platform
                serves as a marketplace facilitating connections between
                parties, and not as a recruitment agency. This clarification is
                provided to ensure compliance and to avoid any potential
                misunderstandings.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(C) VALUE-ADDED SERVICES:</h6>
              <p>
                In addition to basic profile maintenance, job seekers may have
                the option to access value-added services for an additional fee.
                These services may include resume optimization, personalized
                career coaching, and premium visibility features to enhance
                their chances of attracting potential employers. Our goal is to
                provide job seekers with the resources and support they need to
                maximize their employment opportunities in the competitive job
                market.
              </p>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          <h5>3.2 EMPLOYER PAYMENTS:</h5>
          <MDBContainer>
            <MDBRow>
              <h6>(A) FAIR AND TRANSPARENT PRICING:</h6>
              <p>
                Employers using H2BDirect.net to recruit talent under the H-2B
                visa category are responsible for any payments associated with
                the hiring process. Our platform operates on a fair and
                transparent pricing model, where employers only pay for the
                services they utilize. Whether posting job listings, accessing
                candidate profiles, or engaging in visa documentation support,
                employers can rest assured that they are receiving value for
                their investment.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(B) NO HIDDEN CHARGES:</h6>
              <p>
                Unlike traditional recruitment agencies that may impose hidden
                charges or commissions, H2BDirect.net does not engage in
                fee-splitting arrangements with recruiters. We strictly adhere
                to USCIS regulations, ensuring that all fees are transparently
                disclosed and borne by the employing entity. This policy
                safeguards the interests of both employers and job seekers,
                fostering trust and confidence in our platform.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(C) FLEXIBLE PAYMENT OPTIONS:</h6>
              <p>
                To accommodate the diverse needs of our employer clients, we
                offer flexible payment options tailored to their preferences and
                budgetary constraints. Whether opting for pay-as-you-go
                arrangements or bundled service packages, employers have the
                flexibility to choose the payment structure that best aligns
                with their hiring needs and financial considerations.
              </p>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          <h5>3.3 COMPLIANCE ASSURANCE:</h5>
          <MDBContainer>
            <MDBRow>
              <h6>(A) REGULATORY COMPLIANCE:</h6>
              <p>
                H2BDirect.net is committed to upholding the highest standards of
                regulatory compliance, particularly concerning fee arrangements
                and payments in the context of H-2B visa sponsorship. Our
                platform operates in strict accordance with USCIS guidelines,
                ensuring that all fee-related practices are fully compliant with
                applicable laws and regulations. Employers can trust that their
                engagements with H2BDirect.net adhere to the highest ethical and
                legal standards, mitigating the risk of compliance issues or
                legal challenges.
              </p>
            </MDBRow>

            <MDBRow>
              <h6>(B) TRANSPARENT REPORTING:</h6>
              <p>
                To promote transparency and accountability, H2BDirect.net
                provides employers with detailed reports and invoices outlining
                all charges incurred during the recruitment process. Employers
                have access to real-time financial data, enabling them to track
                their expenditures and reconcile payments with the services
                rendered. This transparent reporting mechanism enhances trust
                and confidence in our platform, fostering long-term partnerships
                with our valued clients.
              </p>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
        <MDBRow>
          <h3>4. USER OBLIGATIONS</h3>
        </MDBRow>

        <MDBRow>
          <h5>4.1 ACCURACY OF INFORMATION:</h5>
          <p>
            Ensuring the accuracy of information is paramount at H2BDirect.net.
            We recognize the critical importance of providing reliable and
            up-to-date data to both job seekers and employers using our
            platform. Our commitment to accuracy extends across all facets of
            our operations, from user profiles and job listings to visa
            documentation and regulatory compliance.
          </p>
          <p>
            For job seekers, maintaining accurate and comprehensive profiles is
            fundamental to enhancing their visibility and credibility to
            potential employers. We encourage users to diligently update their
            profiles with the latest qualifications, experiences, and contact
            information to ensure that they present an accurate representation
            of their professional identity. By doing so, job seekers can
            maximize their chances of being matched with suitable employment
            opportunities that align with their skills and aspirations.
          </p>
          <p>
            Similarly, employers rely on accurate information to make informed
            hiring decisions and comply with regulatory requirements. From job
            descriptions and eligibility criteria to visa sponsorship details,
            employers must provide precise and transparent information to
            attract qualified candidates and navigate the complexities of the
            H-2B visa category. We work closely with employers to verify the
            accuracy of their job postings and ensure alignment with USCIS
            guidelines, thereby fostering trust and confidence in our platform.
          </p>
          <p>
            At H2BDirect.net, we employ robust quality assurance measures to
            validate the accuracy of information shared on our platform. This
            includes automated checks for data consistency and integrity, as
            well as manual reviews by our dedicated team of professionals.
            Additionally, we encourage users to report any inaccuracies or
            discrepancies they encounter, allowing us to promptly address and
            rectify the issue to maintain the integrity of our platform.
          </p>
          <p>
            Ultimately, our commitment to accuracy reflects our dedication to
            facilitating fair and transparent interactions between job seekers
            and employers under the H-2B visa category. By upholding the highest
            standards of information integrity, we empower users to make
            informed decisions that drive their recruitment and employment
            objectives forward with confidence and reliability.
          </p>
        </MDBRow>

        <MDBRow>
          <h5>4.2 COMPLIANCE WITH REGULATIONS:</h5>
          <p>
            At H2BDirect.net, adherence to regulations governing the recruitment
            and employment of international workers under the H-2B visa category
            is a cornerstone of our operations. We recognize the complex legal
            landscape surrounding temporary non-agricultural workers in the
            United States and are committed to upholding the highest standards
            of compliance to ensure the integrity of our platform.
          </p>
          <p>
            For job seekers, understanding and complying with USCIS regulations
            are essential steps in pursuing employment opportunities in the
            United States. We provide comprehensive guidance and support to job
            seekers, ensuring they are aware of their rights and
            responsibilities under the H-2B visa program. This includes
            educating users on visa eligibility criteria, application
            procedures, and compliance obligations to mitigate the risk of legal
            issues or visa violations.
          </p>
          <p>
            Similarly, employers must navigate a myriad of regulatory
            requirements when recruiting international workers through our
            platform. From labor certifications and prevailing wage
            determinations to recruitment obligations and visa sponsorship,
            employers must adhere to stringent USCIS guidelines to avoid
            potential penalties or sanctions. We work closely with employers to
            ensure they understand their legal obligations and facilitate
            compliance with all relevant regulations throughout the recruitment
            and employment process.
          </p>
          <p>
            At H2BDirect.net, we maintain a proactive approach to compliance by
            regularly monitoring regulatory developments and updates from USCIS
            and other relevant authorities. Our platform is continuously updated
            to reflect changes in legislation or policy, ensuring that our users
            have access to the most accurate and up-to-date information.
            Additionally, we conduct internal audits and assessments to evaluate
            our compliance procedures and identify areas for improvement,
            thereby safeguarding the integrity of our operations and enhancing
            user trust.
          </p>
          <p>
            By prioritizing compliance with regulations, H2BDirect.net fosters a
            secure and transparent environment for job seekers and employers to
            connect and collaborate under the H-2B visa category. Our unwavering
            commitment to regulatory compliance underscores our dedication to
            facilitating lawful and ethical employment practices, ultimately
            contributing to the success and sustainability of the international
            workforce in the United States.
          </p>
        </MDBRow>
        <MDBRow>
          <h3>5. INTELLECTUAL PROPERTY</h3>
          <p>
            Protecting intellectual property (IP) rights is a fundamental aspect
            of H2BDirect.net's commitment to innovation, creativity, and
            integrity. We recognize the significance of intellectual property in
            fostering innovation, driving competitiveness, and safeguarding the
            unique assets that distinguish our platform in the marketplace.
          </p>
          <p>
            As creators and custodians of proprietary content, technologies, and
            branding elements, we take proactive measures to safeguard our
            intellectual property assets from unauthorized use, reproduction, or
            infringement. This includes securing appropriate legal protections,
            such as patents, trademarks, copyrights, and trade secrets, to
            assert our exclusive rights and prevent unauthorized exploitation by
            third parties.
          </p>
          <p>
            Furthermore, H2BDirect.net respects the intellectual property rights
            of others and expects our users to do the same. We have implemented
            robust policies and procedures to ensure that our platform does not
            facilitate or condone any activities that violate third-party
            intellectual property rights. This includes mechanisms for promptly
            addressing and resolving any claims of infringement or misuse
            brought to our attention, in accordance with applicable laws and
            regulations.
          </p>
          <p>
            At the same time, we recognize the importance of fostering a culture
            of innovation and collaboration within our community. Through
            responsible use of intellectual property and adherence to ethical
            standards, we encourage our users to leverage the wealth of
            knowledge and resources available on our platform to drive positive
            outcomes and create value for themselves and others.
          </p>
        </MDBRow>
        <MDBRow>
          <h3>6. LIMITATION OF LIABILITY</h3>
          <p>
            While H2BDirect.net endeavors to provide a seamless and secure
            platform for connecting international job seekers with US employers
            under the H-2B visa category, it's important to acknowledge certain
            limitations of liability inherent in our operations. We operate
            within the parameters of applicable laws and regulations, and while
            we strive to maintain the highest standards of service, we cannot
            guarantee uninterrupted or error-free access to our platform.
          </p>
          <p>
            H2BDirect.net shall not be held liable for any direct, indirect,
            incidental, special, or consequential damages arising out of or in
            connection with the use of our website or services. This includes,
            but is not limited to, damages for loss of profits, goodwill, data,
            or other intangible losses. While we take reasonable measures to
            ensure the accuracy, reliability, and security of information
            provided on our platform, we cannot warrant the absolute accuracy or
            completeness of such information.
          </p>
          <p>
            Users of H2BDirect.net acknowledge and accept that our platform may
            occasionally experience downtime, technical glitches, or
            interruptions in service due to factors beyond our control, such as
            maintenance, upgrades, or unforeseen circumstances. While we strive
            to minimize such disruptions and restore service expeditiously,
            users agree to hold H2BDirect.net harmless for any inconvenience,
            loss, or damage incurred as a result of such events.
          </p>
          <p>
            Furthermore, H2BDirect.net shall not be held responsible for any
            actions or omissions of third parties, including but not limited to
            employers, recruiters, or other users of our platform. We do not
            endorse or guarantee the accuracy, reliability, or legality of
            content posted by third parties on our platform, and users are
            encouraged to exercise caution and discretion when engaging with
            such content.
          </p>
        </MDBRow>

        <MDBRow>
          <h3>7. DISCLAIMER OF LIABILITY</h3>
          <p>
            H2BDirect.net provides information and services related to the H-2B
            visa category for informational purposes only. While we strive to
            ensure the accuracy and reliability of the information presented on
            our platform, we make no representations or warranties of any kind,
            express or implied, about the completeness, accuracy, reliability,
            suitability, or availability of the information, products, services,
            or related graphics contained on the website for any purpose.
          </p>
          <p>
            Users acknowledge and agree that any reliance they place on such
            information is strictly at their own risk. H2BDirect.net shall not
            be liable for any loss or damage, including without limitation,
            indirect or consequential loss or damage, or any loss or damage
            whatsoever arising from loss of data or profits arising out of, or
            in connection with, the use of our website.
          </p>
          <p>
            In no event shall H2BDirect.net be liable for any direct, indirect,
            incidental, special, or consequential damages arising out of or in
            any way connected with the use of the website or the inability to
            use the website, even if advised of the possibility of such damages.
          </p>
          <p>
            H2BDirect.net does not guarantee the accuracy, reliability,
            completeness, or timeliness of any information on the website. Users
            are encouraged to verify the accuracy and currency of information
            provided on the website before making any decisions or taking any
            actions based on such information.
          </p>
          <p>
            Furthermore, H2BDirect.net does not endorse or recommend any
            specific employers, job seekers, products, services, or information
            that may be advertised or promoted on the website. Any transactions
            or interactions between users of the website are solely the
            responsibility of those users, and H2BDirect.net shall not be liable
            for any disputes, damages, or losses arising from such transactions
            or interactions.
          </p>
        </MDBRow>

        <MDBRow>
          <h3>8. MODIFICATIONS TO TERMS</h3>
          <p>
            H2BDirect.net reserves the right to modify or revise these Terms of
            Service at any time without prior notice. We understand the
            importance of transparency and strive to communicate any changes
            effectively to our users. While we endeavor to provide notice of
            material changes to the Terms, users are encouraged to review the
            Terms regularly to stay informed about updates or modifications. By
            continuing to use our platform after any changes to the Terms, users
            acknowledge and accept the revised Terms. If users do not agree with
            the modified Terms, they have the option to discontinue their use of
            H2BDirect.net. We value the trust and confidence of our users and
            are committed to maintaining open communication and accountability
            in all aspects of our operations.
          </p>
        </MDBRow>

        <MDBRow>
          <h3>9. GOVERNING LAW</h3>
          <p>
            In matters concerning legal disputes or interpretation of the Terms
            of Service, H2BDirect.net operates under the governing law of the
            United States. Any conflicts arising from the use of our platform or
            related services are subject to resolution in the federal or state
            courts located within the jurisdiction specified by H2BDirect.net.
            By using our platform, users consent to the exclusive jurisdiction
            and venue of these courts for the resolution of any disputes. This
            provision ensures consistency and clarity in legal proceedings,
            providing a framework for fair and impartial resolution of
            conflicts. H2BDirect.net remains committed to upholding the rule of
            law and ensuring compliance with applicable regulations to safeguard
            the interests of our users and maintain the integrity of our
            platform.
          </p>
        </MDBRow>

        <MDBRow>
          <h3>10. ARBITRATION</h3>
          <p>
            In the event of any disputes, claims, or controversies arising out
            of or relating to the use of H2BDirect.net or these Terms of
            Service, both parties agree to resolve such matters through binding
            arbitration. Arbitration offers a streamlined and efficient
            alternative to traditional litigation, providing a fair and
            impartial forum for the resolution of conflicts.
          </p>
          <p>
            The arbitration process shall be conducted in accordance with the
            rules and procedures of a mutually agreed-upon arbitration provider,
            or if no such agreement can be reached, the American Arbitration
            Association (AAA). The arbitration shall take place in [insert
            jurisdiction] and be conducted by a single arbitrator appointed in
            accordance with the rules of the chosen arbitration provider.
          </p>
          <p>
            Both parties agree to abide by the arbitrator's decision, which
            shall be final and binding on all parties. The costs and expenses
            associated with the arbitration, including the arbitrator's fees and
            administrative expenses, shall be shared equally between the parties
            unless the arbitrator directs otherwise.
          </p>
          <p>
            By using H2BDirect.net, users acknowledge and accept the arbitration
            provision as the exclusive means of resolving any disputes arising
            from their use of the platform or these Terms of Service. This
            provision promotes efficiency, fairness, and confidentiality in
            dispute resolution, allowing both parties to achieve a swift and
            equitable resolution without the need for costly and protracted
            litigation.
          </p>
        </MDBRow>

        <MDBRow>
          <h3>11. SEVERABILITY</h3>
          <p>
            In the event that any provision of these Terms of Service is found
            to be invalid, unlawful, or unenforceable by a court of competent
            jurisdiction, such provision shall be deemed severable from the
            remaining provisions of these Terms, which shall remain in full
            force and effect to the fullest extent permitted by law.
          </p>
          <p>
            The invalidity, unlawfulness, or unenforceability of any provision
            shall not affect the validity or enforceability of the remainder of
            these Terms, nor shall it impair the ability of either party to
            enforce any other provision of these Terms. In such cases, the
            parties agree to negotiate in good faith to replace the invalid,
            unlawful, or unenforceable provision with a valid and enforceable
            provision that achieves, to the extent possible, the original intent
            and purpose of the invalidated provision.
          </p>
          <p>
            By acknowledging and accepting these Terms of Service, users agree
            to abide by the severability provision and understand that the
            invalidity or unenforceability of any provision shall not invalidate
            or impair the enforceability of the remaining provisions of these
            Terms. This provision ensures that the rights and obligations of the
            parties under these Terms remain intact and enforceable to the
            fullest extent permitted by law, promoting clarity, fairness, and
            consistency in the interpretation and application of these Terms.
          </p>
        </MDBRow>

        <MDBRow>
          <h3>12. CONTACT INFORMATION</h3>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at{" "}
            <a href="mailto:info@h2bdirect.net">info@h2bdirect.net</a>.
          </p>
          <p>
            By using the Website, you acknowledge that you have read,
            understood, and agree to be bound by these Terms of Service.
          </p>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default TermsAndConditions;

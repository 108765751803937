import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import colorConfigs from "../../configs/colorConfig";
import Logo from "./../../images/H2B-DirectLogo-cropped.png";

export default function Topbar() {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar>
        <img src={Logo} alt="H2B Direct Logo" style={{ width: "100px" }} />
      </Toolbar>
    </AppBar>
  );
}

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import defaultConfig from "../aws-configure";

const AdminExperienceModal = ({
  isOpen,
  toggle,
  candidateData,
  setCandidateData,
  mode,
  editExperienceData,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    if (mode === "edit" && editExperienceData) {
      setCompanyName(editExperienceData.companyName || "");
      setPosition(editExperienceData.position || "");
      setState(editExperienceData.state || "");
      setCountry(editExperienceData.country || "");
      setStartDate(editExperienceData.startDate || "");
      setEndDate(editExperienceData.endDate || "");
      setJobDescription(editExperienceData.jobDescription || "");
    } else if (mode === "add") {
      setCompanyName("");
      setPosition("");
      setState("");
      setCountry("");
      setStartDate("");
      setEndDate("");
      setJobDescription("");
    }
  }, [mode, editExperienceData]);

  const handleWorkExperienceSubmit = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    const newWorkExperience = {
      userId: candidateData.id,
      role: candidateData.role,
      companyName: companyName,
      position: position,
      state: state,
      country: country,
      startDate: startDate,
      endDate: endDate,
      jobDescription: jobDescription,
    };

    try {
      let updatedUserData;
      if (mode === "add") {
        updatedUserData = {
          userId: candidateData.id,
          role: candidateData.role,
          ...candidateData,
          workExperiences: [
            ...(candidateData.workExperiences || []),
            newWorkExperience,
          ],
        };
      } else if (mode === "edit") {
        const updatedWorkExperiences = (
          candidateData.workExperiences || []
        ).map((experience) => {
          if (experience === editExperienceData) {
            return newWorkExperience;
          }
          return experience;
        });

        updatedUserData = {
          userId: candidateData.id,
          role: candidateData.role,
          ...candidateData,
          workExperiences: updatedWorkExperiences,
        };
      }

      const updateWorkExperience = await axios.post(
        `${defaultConfig.apiUrl}adminUpdateUser`,
        updatedUserData,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      setCandidateData(updateWorkExperience.data);
      toggle();
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
      <ModalHeader toggle={toggle}>
        {mode === "add" ? "Add Experience" : "Edit Experience"}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleWorkExperienceSubmit}>
          <MDBRow sm="6">
            <MDBCol>
              <FormGroup>
                <Label for="companyName">Company Name</Label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={companyName}
                  required
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </FormGroup>
            </MDBCol>
            <MDBCol sm="6">
              <FormGroup>
                <Label for="position">Position</Label>
                <Input
                  type="text"
                  name="position"
                  id="position"
                  required
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </FormGroup>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="6">
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </FormGroup>
            </MDBCol>
            <MDBCol>
              <FormGroup>
                <Label for="country">Country</Label>
                <Input
                  type="text"
                  name="country"
                  id="country"
                  value={country}
                  required
                  onChange={(e) => setCountry(e.target.value)}
                />
              </FormGroup>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="6">
              <FormGroup>
                <Label for="startDate">Start Date</Label>
                <Input
                  type="text"
                  name="startDate"
                  id="startDate"
                  value={startDate}
                  required
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormGroup>
            </MDBCol>
            <MDBCol sm="6">
              <FormGroup>
                <Label for="endDate">End Date</Label>
                <Input
                  type="text"
                  name="endDate"
                  id="endDate"
                  value={endDate}
                  required
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormGroup>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <FormGroup>
                <Label for="jobDescription">Job Description</Label>
                <Input
                  type="textarea"
                  name="jobDescription"
                  id="jobDescription"
                  required
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                />
              </FormGroup>
            </MDBCol>
          </MDBRow>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={saveLoading}>
              {saveLoading
                ? mode === "add"
                  ? "Adding"
                  : "Saving"
                : mode === "add"
                ? "Add"
                : "Save"}
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AdminExperienceModal;

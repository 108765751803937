import React, { useEffect, useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import {
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import DefaultPhoto from "./../images/DefaultPhoto.jpg";
import { useNavigate } from "react-router-dom";
import defaultConfig from "../aws-configure";

export default function EmployerDashboard({
  userData,
  setUserData,
  interviewList,
  setInterviewList,
}) {
  const [agencyList, setAgencyList] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [newUserModal, setNewUserModal] = useState(false);
  const [selectAgencyModal, setSelectAgencyModal] = useState(false);
  const [hiredCandidateStatus, setHiredCandidateStatus] = useState(false);

  const [jobTypeHired, setJobTypeHired] = useState(null);
  const [candidateIdHired, setCandidateIdHired] = useState(null);
  const [agencyId, setAgencyId] = useState(null);
  const [saveAgencyLoading, setSaveAgencyLoading] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("userToken");
        const response = await axios.get(`${defaultConfig.apiUrl}getAgencies`, {
          headers: {
            Authorization: token,
          },
        });
        setAgencyList(response.data);
      } catch (error) {
        console.error("Error fetching interview data:", error);
      }
    };
    fetchData();
  }, [userData]);

  const handleStatusChange = async (
    candidateId,
    jobType,
    newStatus,
    interviewList
  ) => {
    let interviewToUpdate = {};
    try {
      interviewToUpdate = {
        id: candidateId,
        jobType: jobType,
        status: newStatus,
      };

      // Make an API call to update the employmentStatus in the interview list
      const response = await axios.post(
        `${defaultConfig.apiUrl}updateInterview`,
        interviewToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );

      // If the update is successful, update the interviewList state
      if (response.status === 200) {
        // Extract the values from the interviewList object
        const interviewsArray = Object.values(interviewList);
        // Find the interview object with the matching id
        const interview = interviewsArray.find(
          (interview) => interview.id === candidateId
        );

        // If the interview object is found, update its status
        if (interview) {
          interview.status = newStatus;
          // Update the interviewList state
          setInterviewList(interviewsArray);
        } else {
          console.error("Candidate not found in interview list");
        }
      } else {
        console.error("Failed to update employment status");
      }
    } catch (error) {
      console.error("Error updating employment status:", error);
    }
  };

  const openHiredModal = async (
    candidateId,
    candidateStatus,
    jobType,
    newStatus,
    interviewList
  ) => {
    setJobTypeHired(jobType);
    setCandidateIdHired(candidateId);
    setHiredCandidateStatus(candidateStatus);
    setSelectAgencyModal(true);
  };

  const navigate = useNavigate();

  const handleNewModal = () => {
    setNewUserModal(!newUserModal);
    navigate("/app/employer/settings");
  };

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const filterInterviewsByStatus = (status) => {
    if (!interviewList) return [];
    return Object.values(interviewList).filter(
      (interview) => interview.status === status
    );
  };

  const filterInterviewsByApplicationStatus = (status) => {
    if (!interviewList) return [];
    return Object.values(interviewList).filter(
      (interview) => interview.h2bApplicationStatus === status
    );
  };

  const handleSelectAgency = (id, selectedCompany) => {
    setAgencyId(id);
    setSelectedCompany(selectedCompany);
  };

  const handleHireCandidateSave = async (
    jobTypeHired,
    candidateIdHired,
    hiredCandidateStatus,
    agencyId,
    interviewList
  ) => {
    setSaveAgencyLoading(true);
    let updateInterview = {};
    if (hiredCandidateStatus === "Hired") {
      updateInterview = {
        id: candidateIdHired,
        agencyId: agencyId,
      };

      try {
        const response = await axios.post(
          `${defaultConfig.apiUrl}updateInterviewWithAgencyId`,
          updateInterview,
          {
            headers: {
              Authorization: sessionStorage.getItem("userToken"),
            },
          }
        );
        // If the update is successful, update the interviewList state
        if (response.status === 200) {
          // Update interviewList to include agencyId
          const updatedInterviewList = {}; // Initialize as an empty object

          Object.keys(interviewList).forEach((key) => {
            const interview = interviewList[key];
            updatedInterviewList[key] =
              interview.id === candidateIdHired
                ? { ...interview, agency_id: agencyId }
                : interview;
          });

          setInterviewList(updatedInterviewList);
        } else {
          console.error("Failed to update candidate agency status");
        }
      } catch (error) {
        console.error("Error Updating Agency: ", error.message);
      } finally {
        setSaveAgencyLoading(false);
        setSelectAgencyModal(false);
      }
    } else {
      updateInterview = {
        id: candidateIdHired,
        jobType: jobTypeHired,
        status: "Hired",
        agencyId: agencyId,
      };

      try {
        const response = await axios.post(
          `${defaultConfig.apiUrl}updateInterview`,
          updateInterview,
          {
            headers: {
              Authorization: sessionStorage.getItem("userToken"),
            },
          }
        );

        // If the update is successful, update the interviewList state
        if (response.status === 200) {
          // Update interviewList to include agencyId
          const updatedInterviewList = {}; // Initialize as an empty object

          Object.keys(interviewList).forEach((key) => {
            const interview = interviewList[key];
            updatedInterviewList[key] =
              interview.id === candidateIdHired
                ? { ...interview, status: "Hired", agency_id: agencyId }
                : interview;
          });

          setInterviewList(updatedInterviewList);
        } else {
          console.error("Failed to update employment status");
        }
      } catch (error) {
        console.error("Error Hiring Candidate: ", error.message);
      } finally {
        setSaveAgencyLoading(false);
        setSelectAgencyModal(false);
      }
    }
  };

  useEffect(() => {
    if (userData && userData.status !== "Enabled") {
      setNewUserModal(true);
    }
  }, [userData]);

  const toggle = () => {
    setNewUserModal(!newUserModal);
  };

  const toggleSelectAgency = () => {
    setSelectAgencyModal(!selectAgencyModal);
  };

  function findCompanyNameById(id) {
    if (!agencyList) return null; // Check if agencyList is undefined or null

    for (const key of Object.keys(agencyList)) {
      const item = agencyList[key];
      if (item && item.id === id) {
        // Ensure item is not null or undefined before accessing its properties
        return item.companyName;
      }
    }
    return null;
  }

  const interviewingList = filterInterviewsByStatus("Being Interviewed");
  const hiredList = filterInterviewsByStatus("Hired");
  const approvedList = filterInterviewsByApplicationStatus("Approved");
  const deniedList = filterInterviewsByApplicationStatus("Denied");
  const prospectsToHire = interviewList ? Object.keys(interviewList).length : 0;

  return (
    <section>
      <Modal isOpen={newUserModal} toggle={toggle} centered>
        <ModalBody>
          Please wait for your account to be reviewed and approved by an admin.
          In the mean time, please ensure your profile information is updated
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleNewModal}>
            Update Profile
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal isOpen={selectAgencyModal} toggle={toggleSelectAgency} centered>
        <ModalBody>
          <span className="text-primary font-italic me-1">
            Preferred Agency
          </span>
          <hr />
          <MDBRow>
            <div>
              <p>
                From the list below select your preferred agency to work with:
              </p>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>
                  {selectedCompany ? selectedCompany : "Select Company"}
                </DropdownToggle>
                <DropdownMenu>
                  {agencyList?.map((item) => (
                    <DropdownItem
                      key={item.id}
                      onClick={() =>
                        handleSelectAgency(item.id, item.companyName)
                      }
                    >
                      {item.companyName}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </MDBRow>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={saveAgencyLoading}
            onClick={() =>
              handleHireCandidateSave(
                jobTypeHired,
                candidateIdHired,
                hiredCandidateStatus,
                agencyId,
                interviewList
              )
            }
          >
            {saveAgencyLoading ? "Saving... " : "Save"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <MDBContainer>
        <MDBRow>
          <h4>Employer Dashboard</h4>
          <p>Overview of your progress.</p>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <h4>
                  Candidate Limit: {prospectsToHire}/{userData.candidatesToHire}
                </h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <h4>
                  {interviewingList && interviewingList.length} Interviewing
                </h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <h4>{hiredList.length} Candidate Hired</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Active Interviews{" "}
                  </span>
                  <span className="badge rounded-pill bg-primary">
                    {interviewingList && interviewingList.length}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "15%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            First Name
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Last Name
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Status
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {interviewingList.length > 0 ? (
                          interviewingList.map((candidate, index) => (
                            <tr key={candidate.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <img
                                  src={
                                    candidate.candidate?.photoUrl
                                      ? candidate.candidate?.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.candidate.firstName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.candidate.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.jobType}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-secondary dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Update
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() =>
                                          handleStatusChange(
                                            candidate.id,
                                            candidate.jobType,
                                            "Not Hired",
                                            interviewList
                                          )
                                        }
                                      >
                                        Open
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() =>
                                          openHiredModal(
                                            candidate.id,
                                            candidate.status,
                                            candidate.jobType,
                                            "Hired",
                                            interviewList
                                          )
                                        }
                                      >
                                        Hire
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No interviews available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Hired Candidates{" "}
                  </span>
                  <span className="badge rounded-pill bg-success">
                    {hiredList.length}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "15%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Agency
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Edit Agency
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {hiredList.length > 0 ? (
                          hiredList.map((candidate, index) => (
                            <tr key={candidate.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <img
                                  src={
                                    candidate.candidate?.photoUrl
                                      ? candidate.candidate?.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.candidate.firstName}{" "}
                                {candidate.candidate.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.jobType}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {findCompanyNameById(candidate.agency_id)}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    openHiredModal(
                                      candidate.id,
                                      candidate.status,
                                      candidate.jobType,
                                      "Hired",
                                      interviewList
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No hired candidates
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Approved{" "}
                  </span>
                  <span className="badge rounded-pill bg-success">
                    {approvedList.length}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "15%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "30%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "30%" }}>
                            Agency
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {approvedList.length > 0 ? (
                          approvedList.map((candidate, index) => (
                            <tr key={candidate.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <img
                                  src={
                                    candidate.candidate?.photoUrl
                                      ? candidate.candidate?.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "30%" }}
                              >
                                {candidate.candidate.firstName}{" "}
                                {candidate.candidate.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "30%" }}
                              >
                                {candidate.agency.companyName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.jobType}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No interviews available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">Denied </span>
                  <span className="badge rounded-pill bg-danger">
                    {deniedList.length}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "15%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "30%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "30%" }}>
                            Agency
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {deniedList.length > 0 ? (
                          deniedList.map((candidate, index) => (
                            <tr key={candidate.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <img
                                  src={
                                    candidate.candidate?.photoUrl
                                      ? candidate.candidate?.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "30%" }}
                              >
                                {candidate.candidate.firstName}{" "}
                                {candidate.candidate.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "30%" }}
                              >
                                {candidate.agency.companyName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.jobType}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No interviews available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

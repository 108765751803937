import React, { useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Input, Label, Button, Form } from "reactstrap";
import axios from "axios";
import defaultConfig from "../aws-configure";

export default function EditAgencyProfile({ userData, setUserData }) {
  const [employerInfoLoading, setEmployerInfoLoading] = useState(false);
  const [firstName, setFirstName] = useState(userData.firstName || "");
  const [lastName, setLastName] = useState(userData.lastName || "");
  const [companyName, setCompanyName] = useState(userData.companyName || "");
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber || "");
  const [address, setAddress] = useState(userData.address || "");

  const handleEmployerInfoSubmit = async (e) => {
    e.preventDefault();
    setEmployerInfoLoading(true);

    const employerDataToUpdate = {
      companyName: companyName,
      phoneNumber: phoneNumber,
      address: address,
    };

    try {
      const updatedEmployer = await axios.post(
        `${defaultConfig.apiUrl}updateUser`,
        employerDataToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      setUserData(updatedEmployer.data);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setEmployerInfoLoading(false);
    }
  };

  return (
    <section>
      <MDBContainer>
        <MDBRow>
          <h4>Edit Profile</h4>
          <p>Modify your profile details here</p>
        </MDBRow>
        <MDBRow className="row-eq-height">
          <MDBCol lg="6">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form onSubmit={handleEmployerInfoSubmit}>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Personal Information
                    </span>{" "}
                    <span>
                      <Button
                        color="info"
                        size="sm"
                        type="submit"
                        disabled={employerInfoLoading}
                      >
                        {employerInfoLoading ? "Updating..." : "Update"}
                      </Button>
                    </span>
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="companyName">Company Name</Label>
                      <Input
                        id="companyName"
                        name="companyName"
                        type="text"
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="firstName">Contact First Name</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                      />
                    </MDBCol>
                    <MDBCol sm="6">
                      <Label for="lastName">Contact Last Name</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        value={userData.email}
                        disabled
                      />
                    </MDBCol>
                    <MDBCol sm="6">
                      <Label for="telephone">Telephone</Label>
                      <Input
                        id="telephone"
                        name="telephone"
                        type="tele"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="address">Address</Label>
                      <Input
                        id="address"
                        name="Address"
                        placeholder={
                          userData.address
                            ? userData.address
                            : "Enter Address Here"
                        }
                        type="text"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {/* <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Change Password
                    </span>
                    
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="4">
                      <Label for="currentPassword">Current Password</Label>
                      <Input
                        id="currentPassword"
                        name="currentPassword"
                        type="password"
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="4">
                      <Label for="newPassword">New Password</Label>
                      <Input
                        id="newPassword"
                        name="newPassword"
                        type="password"
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="4">
                      <Label for="confirmPassword">Confirm Password</Label>
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="4">
                      <span>
                        <Button color="success" size="sm">
                          Change
                        </Button>
                      </span>
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow> */}
      </MDBContainer>
    </section>
  );
}

import React, { useState } from "react";
// import "./Checkout.css";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import axios from "axios";
import defaultConfig from "../aws-configure";

const Checkout = ({ userData, setUserData, paymentModal, setPaymentModal }) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  const paypalOptions = {
    locale: "en_US",
  };

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "74.99",
          },
        },
      ],
    });
  };

  const updateDatabase = async () => {
    const statusToUpdate = {
      subscriptionStatus: "Active",
    };

    try {
      const updateSubscriptionStatus = await axios.post(
        `${defaultConfig.apiUrl}updateUser`,
        statusToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      setUserData(updateSubscriptionStatus.data);
      setPaymentModal(false);
    } catch (error) {
      console.error("There was a problem processing payment:", error);
    }
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      // const name = details.payer.name.given_name;

      updateDatabase()
        .then(() => {
          console.log("Database updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating database:", error);
        });
    });
  };

  return (
    <div className="checkout">
      {isPending ? (
        <p>LOADING...</p>
      ) : (
        <>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
            options={paypalOptions}
          />
        </>
      )}
    </div>
  );
};

export default Checkout;

import React, { useEffect, useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import {
  Table,
  Modal,
  ModalBody,
  Button,
  ModalFooter,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import DefaultPhoto from "./../images/DefaultPhoto.jpg";
import { useNavigate } from "react-router-dom";
import defaultConfig from "../aws-configure";

export default function AgencyDashboard({
  userData,
  setUserData,
  interviewList,
  setInterviewList,
}) {
  const [agencyList, setAgencyList] = useState(null);
  const [newUserModal, setNewUserModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [candidateModalData, setCandidateModalData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const openModal = (candidate) => {
    setCandidateModalData(candidate);
    setIsOpen(true);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("userToken");
        const response = await axios.get(
          `${defaultConfig.apiUrl}getInterviewsByAgencyId`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setAgencyList(response.data);
      } catch (error) {
        console.error("Error fetching interview data:", error);
      }
    };
    fetchData();
  }, [userData]);

  const handleStatusChange = async (
    interviewId,
    applicationStatus,
    agencyList
  ) => {
    try {
      const interviewToUpdate = {
        id: interviewId,
        applicationStatus: applicationStatus,
      };

      // Make an API call to update the employmentStatus in the interview list
      const response = await axios.post(
        `${defaultConfig.apiUrl}agencyUpdateApplicationStatus`,
        interviewToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );

      // If the update is successful, update the interviewList state
      if (response.status === 200) {
        // Extract the values from the interviewList object
        const interviewsArray = Object.values(agencyList);
        // Find the interview object with the matching id
        const interview = interviewsArray.find(
          (interview) => interview.id === interviewId
        );

        // If the interview object is found, update its status
        if (interview) {
          interview.h2bApplicationStatus = applicationStatus;
          // Update the interviewList state
          setAgencyList(interviewsArray);
        } else {
          console.error("Candidate not found in interview list");
        }
      } else {
        console.error("Failed to update application status");
      }
    } catch (error) {
      console.error("Error updating application status:", error);
    }
  };

  const filterInterviewsByStatus = (status) => {
    if (!agencyList) return [];
    return Object.values(agencyList).filter(
      (interview) => interview.h2bApplicationStatus === status
    );
  };

  const interviewingList = filterInterviewsByStatus("Pending");
  const hiredList = filterInterviewsByStatus("Approved");
  const deniedList = filterInterviewsByStatus("Denied");

  useEffect(() => {
    if (userData && userData.status !== "Enabled") {
      setNewUserModal(true);
    }
  }, [userData]);

  const toggle = () => {
    setNewUserModal(!newUserModal);
  };

  const handleNewModal = () => {
    setNewUserModal(!newUserModal);
    navigate("/app/agency/settings");
  };

  return (
    <section>
      <Modal isOpen={newUserModal} toggle={toggle} centered>
        <ModalBody>
          Please wait for your account to be reviewed and approved by an admin.
          In the mean time, please ensure your profile information is updated
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleNewModal}>
            Update Profile
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <MDBContainer>
        <MDBRow>
          <h4>Agency Dashboard</h4>
          <p>An overview of your assigned candidates</p>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Waiting To Be Processed{" "}
                  </span>
                  <span className="badge rounded-pill bg-primary">
                    {interviewingList && interviewingList.length}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            Company
                          </th>
                          <th className="text-center" style={{ width: "15%" }}>
                            Position
                          </th>
                          <th className="text-center" style={{ width: "40%" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {interviewingList.length > 0 ? (
                          interviewingList.map((candidate, index) => (
                            <tr key={candidate.id}>
                              <Modal
                                isOpen={isOpen}
                                toggle={toggleModal}
                                size="xl"
                                centered
                                scrollable
                              >
                                <ModalBody className="modal-height">
                                  {candidateModalData && (
                                    <>
                                      <MDBContainer>
                                        <MDBRow>
                                          <MDBCol lg="4">
                                            <MDBCard className="mb-4">
                                              <MDBCardBody className="text-center">
                                                <MDBCardImage
                                                  src={
                                                    candidateModalData
                                                      ?.candidate.photoUrl
                                                      ? candidateModalData
                                                          .candidate.photoUrl
                                                      : DefaultPhoto
                                                  }
                                                  alt="avatar"
                                                  className="rounded"
                                                  style={{
                                                    width: "220px",
                                                    paddingBottom: "10px",
                                                  }}
                                                  fluid
                                                />
                                                <h2 className="text-muted mb-1">
                                                  {candidateModalData &&
                                                    `${candidateModalData.candidate.firstName} ${candidateModalData.candidate.lastName}`}
                                                </h2>
                                                <div className="d-flex justify-content-center mb-1">
                                                  <p>
                                                    <span className="badge rounded-pill bg-primary">
                                                      {candidateModalData &&
                                                        candidateModalData.jobType}
                                                    </span>{" "}
                                                  </p>
                                                </div>
                                              </MDBCardBody>
                                            </MDBCard>
                                          </MDBCol>
                                          <MDBCol lg="8">
                                            <MDBCard className="mb-4">
                                              <MDBCardBody>
                                                <MDBRow>
                                                  <MDBCol sm="3">
                                                    <MDBCardText>
                                                      Email Address
                                                    </MDBCardText>
                                                  </MDBCol>
                                                  <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">
                                                      {candidateModalData &&
                                                        `${candidateModalData.candidate.email}`}
                                                    </MDBCardText>
                                                  </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                  <MDBCol sm="3">
                                                    <MDBCardText>
                                                      Telephone Number
                                                    </MDBCardText>
                                                  </MDBCol>
                                                  <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">
                                                      {candidateModalData &&
                                                        `${candidateModalData.candidate.phoneNumber}`}
                                                    </MDBCardText>
                                                  </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                  <MDBCol sm="3">
                                                    <MDBCardText>
                                                      Address
                                                    </MDBCardText>
                                                  </MDBCol>
                                                  <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">
                                                      {candidateModalData &&
                                                        `${candidateModalData.candidate.address}, ${candidateModalData.candidate.country}`}
                                                    </MDBCardText>
                                                  </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                  <MDBCol sm="3">
                                                    <MDBCardText>
                                                      Gender
                                                    </MDBCardText>
                                                  </MDBCol>
                                                  <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">
                                                      {candidateModalData &&
                                                        `${candidateModalData.candidate.gender}`}
                                                    </MDBCardText>
                                                  </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                  <MDBCol sm="3">
                                                    <MDBCardText>
                                                      Education Level
                                                    </MDBCardText>
                                                  </MDBCol>
                                                  <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">
                                                      {candidateModalData &&
                                                        `${candidateModalData.candidate.educationLevel}`}
                                                    </MDBCardText>
                                                  </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                  <MDBCol sm="3">
                                                    <MDBCardText>
                                                      First Time Applicant
                                                    </MDBCardText>
                                                  </MDBCol>
                                                  <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">
                                                      {candidateModalData &&
                                                      candidateModalData
                                                        .candidate
                                                        .firstTimer === "true"
                                                        ? "Yes"
                                                        : "No"}
                                                    </MDBCardText>
                                                  </MDBCol>
                                                </MDBRow>
                                              </MDBCardBody>
                                            </MDBCard>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          {candidateModalData &&
                                            candidateModalData.candidate
                                              .workExperiences &&
                                            candidateModalData.candidate.workExperiences.map(
                                              (experience, index) => (
                                                <MDBCol md="6" key={index}>
                                                  <MDBCard className="mb-4">
                                                    <MDBCardBody>
                                                      <MDBCardText className="mb-4">
                                                        <span className="text-primary font-italic me-1">
                                                          Work Experience
                                                        </span>
                                                      </MDBCardText>
                                                      <hr />
                                                      <MDBRow>
                                                        <MDBCol sm="3">
                                                          <MDBCardText>
                                                            Company
                                                          </MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="9">
                                                          <MDBCardText className="text-muted">
                                                            {
                                                              experience.companyName
                                                            }
                                                          </MDBCardText>
                                                        </MDBCol>
                                                      </MDBRow>
                                                      <MDBRow>
                                                        <MDBCol sm="3">
                                                          <MDBCardText>
                                                            Location
                                                          </MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="9">
                                                          <MDBCardText className="text-muted">
                                                            {experience.state},{" "}
                                                            {experience.country}
                                                          </MDBCardText>
                                                        </MDBCol>
                                                      </MDBRow>
                                                      <MDBRow>
                                                        <MDBCol sm="3">
                                                          <MDBCardText>
                                                            Position
                                                          </MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="9">
                                                          <MDBCardText className="text-muted">
                                                            {
                                                              experience.position
                                                            }
                                                          </MDBCardText>
                                                        </MDBCol>
                                                      </MDBRow>
                                                      <MDBRow>
                                                        <MDBCol sm="3">
                                                          <MDBCardText>
                                                            Start Date
                                                          </MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="9">
                                                          <MDBCardText className="text-muted">
                                                            {
                                                              experience.startDate
                                                            }
                                                          </MDBCardText>
                                                        </MDBCol>
                                                      </MDBRow>
                                                      <MDBRow>
                                                        <MDBCol sm="3">
                                                          <MDBCardText>
                                                            End Date
                                                          </MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="9">
                                                          <MDBCardText className="text-muted">
                                                            {experience.endDate}
                                                          </MDBCardText>
                                                        </MDBCol>
                                                      </MDBRow>
                                                      <hr />
                                                      <MDBRow>
                                                        <MDBCol
                                                          sm="12"
                                                          className="mb-2"
                                                        >
                                                          <MDBCardText>
                                                            Job Description
                                                          </MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="12">
                                                          <MDBCardText className="text-muted">
                                                            {
                                                              experience.jobDescription
                                                            }
                                                          </MDBCardText>
                                                        </MDBCol>
                                                      </MDBRow>
                                                    </MDBCardBody>
                                                  </MDBCard>
                                                </MDBCol>
                                              )
                                            )}
                                        </MDBRow>
                                      </MDBContainer>
                                    </>
                                  )}
                                </ModalBody>
                              </Modal>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "10%" }}
                              >
                                <img
                                  src={
                                    candidate.candidate?.photoUrl
                                      ? candidate.candidate?.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.candidate.firstName}{" "}
                                {candidate.candidate.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "10%" }}
                              >
                                {candidate.employer.companyName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                {candidate.jobType}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "40%" }}
                              >
                                <span>
                                  <Button
                                    onClick={() => openModal(candidate)}
                                    size="sm"
                                    color="primary"
                                  >
                                    View More
                                  </Button>
                                </span>{" "}
                                <span>
                                  <ButtonGroup size="sm">
                                    <Dropdown
                                      isOpen={dropdownOpen}
                                      toggle={toggleDropdown}
                                    >
                                      <DropdownToggle
                                        caret
                                        size="sm"
                                        color="secondary"
                                      >
                                        Update
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() =>
                                            handleStatusChange(
                                              candidate.id,
                                              "Approved",
                                              agencyList
                                            )
                                          }
                                        >
                                          Approve
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleStatusChange(
                                              candidate.id,
                                              "Denied",
                                              agencyList
                                            )
                                          }
                                        >
                                          Deny
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </ButtonGroup>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No assigned candidates
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Approved{" "}
                  </span>
                  <span className="badge rounded-pill bg-success">
                    {hiredList.length}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "15%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "30%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "30%" }}>
                            Company
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {hiredList.length > 0 ? (
                          hiredList.map((candidate, index) => (
                            <tr key={candidate.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <img
                                  src={
                                    candidate.candidate?.photoUrl
                                      ? candidate.candidate?.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "30%" }}
                              >
                                {candidate.candidate.firstName}{" "}
                                {candidate.candidate.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "30%" }}
                              >
                                {candidate.employer.companyName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.jobType}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No interviews available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">Denied </span>
                  <span className="badge rounded-pill bg-danger">
                    {deniedList && deniedList.length}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "15%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Company
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {deniedList.length > 0 ? (
                          deniedList.map((candidate, index) => (
                            <tr key={candidate.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <img
                                  src={
                                    candidate.candidate?.photoUrl
                                      ? candidate.candidate?.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.candidate.firstName}{" "}
                                {candidate.candidate.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.employer.companyName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {candidate.jobType}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-secondary "
                                    onClick={() =>
                                      handleStatusChange(
                                        candidate.id,
                                        "Approved",
                                        agencyList
                                      )
                                    }
                                  >
                                    Approve
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No assigned candidates
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

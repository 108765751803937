import React from "react";
import Hero from "../components/Hero";
import LeftImageCard from "../components/LeftImageCard";
import RightImageCard from "../components/RightImageCard";
import ImageCard from "../components/ImageCard";
import AboutImg from "./../images/2151294558.jpg";
import TravelImage from "./../images/travel-concept-with-worldwide-landmarks.jpg";

export default function AboutUs() {
  const heroArray = [
    {
      image: require("../images/around-the-world.jpg"),
      headerText: "About H2B Direct",
      subText: "Learn More About Us",
    },
  ];

  return (
    <div>
      <div>
        <Hero
          image={heroArray[0].image}
          headerText={heroArray[0].headerText}
          subText={heroArray[0].subText}
        />
      </div>
      <div className="">
        <div>
          <ImageCard
            imageSide="right"
            hasHeader="false"
            cardHeader="Mission Statement"
            cardParagraph="H2B Direct is a global meeting-place for all members of the H2B community. This
            community includes: immigration lawyers, US employers, international job seekers,
            management companies, local agencies and housing agents. Our platform focuses on
            bridging the gap between US employers and international talent, we also simplify the
            H2B visa process by inviting all parties needed to complete document filing, consulate
            and relocation processes."
            showbtn="false"
            btnTitle=""
            cardPhoto={AboutImg}
          />
        </div>
        <hr
          style={{
            width: "50%",
            margin: "0 auto",
            color: "#DE3163",
            height: "3px",
          }}
        />
        <div className="">
          <ImageCard
            imageSide="left"
            hasHeader="false"
            cardParagraph="Of course it doesn't end with the H2B Visa only, although one of the forerunning work
            visa categories put forth by the USCIS, the H2B visa has limits and caps. To give our
            job seekers extended opportunities throughout and after the H2B season, employers
            from other visa categories are invited to join our community where candidate profiles
            are showcased. Other visa categories include: Tier 2 and 3 visas(UK), The C1D
            Crew(Ship), Internship/Trainees and other J1 categories, 482 Skilled workers(Australia),
            Q1, L, O and other cultural exchange, training and work visa program types."
            showbtn="false"
            btnTitle=""
            cardPhoto={TravelImage}
          />
        </div>
        {/* <div id="fifth" className="reveal">
          <div className="container">
            <h1>Get In Touch</h1>
            <div>
              <a href={{}}>
                <img
                  src="https://cdn.iconscout.com/icon/premium/png-64-thumb/address-blue-circle-location-map-marker-navigation-icon-45868.png"
                  alt=" "
                />
                <span>
                  <h3>Address</h3>
                  <p>4813 Woodland Ave Royal Oak, Michigan - 48073, USA</p>
                </span>
              </a>
              <a href={{}}>
                <img
                  src="https://cdn.iconscout.com/icon/free/png-64/phone-2666572-2212584.png"
                  alt=" "
                />
                <span>
                  <h3>Phone</h3>
                  <p>+1 248 672 1972</p>
                </span>
              </a>
              <a href={{}}>
                <img
                  src="https://cdn.iconscout.com/icon/free/png-64/gmail-2489176-2082900.png"
                  alt=" "
                />
                <span>
                  <h3>E-mail</h3>
                  <p>Sales@Ecerasystem.com</p>
                </span>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

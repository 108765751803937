import React, { useState, useEffect } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Modal, ModalBody } from "reactstrap";
import {
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import axios from "axios";
import DefaultPhoto from "./../images/DefaultPhoto.jpg";
import defaultConfig from "../aws-configure";

export default function AdminDashboard({ userData, setUserData }) {
  const [candidateList, setCandidateList] = useState(null);
  const [employerList, setEmployerList] = useState(null);
  const [agencyList, setAgencyList] = useState(null);
  const [interviewList, setInterviewList] = useState(null);
  const [disableEmployerLoading, setDisableEmployerLoading] = useState(false);
  const [disableAgencyLoading, setDisableAgencyLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [employerData, setEmployerData] = useState(false);

  const toggleModal = (employer) => {
    setEmployerData(employer);
    setIsOpen(!isOpen);
  };

  const handleStatusChange = async (
    candidateId,
    jobType,
    newStatus,
    interviewList
  ) => {
    try {
      const interviewToUpdate = {
        id: candidateId,
        jobType: jobType,
        status: newStatus,
      };

      // Make an API call to update the employmentStatus in the interview list
      const response = await axios.post(
        `${defaultConfig.apiUrl}updateInterview`,
        interviewToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );

      // If the update is successful, update the interviewList state
      if (response.status === 200) {
        // Extract the values from the interviewList object
        const interviewsArray = Object.values(interviewList);
        // Find the interview object with the matching id
        const interview = interviewsArray.find(
          (interview) => interview.id === candidateId
        );

        // If the interview object is found, update its status
        if (interview) {
          interview.status = newStatus;
          // Update the interviewList state
          setInterviewList(interviewsArray);
        } else {
          console.error("Candidate not found in interview list");
        }
      } else {
        console.error("Failed to update employment status");
      }
    } catch (error) {
      console.error("Error updating employment status:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("userToken");

        const candidateParam = new URLSearchParams({
          role: "Candidate",
        });

        const employerParam = new URLSearchParams({
          role: "Employer",
        });

        const agencyParam = new URLSearchParams({
          role: "Agency",
        });

        const candidateResponse = await axios.get(
          `${defaultConfig.apiUrl}adminGetUsersByRole?${candidateParam}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // Update candidateList state with the data received from the API
        setCandidateList(candidateResponse.data);

        const employerResponse = await axios.get(
          `${defaultConfig.apiUrl}adminGetUsersByRole?${employerParam}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // Update employerList state with the data received from the API
        setEmployerList(employerResponse.data);

        const agencyResponse = await axios.get(
          `${defaultConfig.apiUrl}adminGetUsersByRole?${agencyParam}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // Update employerList state with the data received from the API
        setAgencyList(agencyResponse.data);

        const getInterviews = await axios.get(
          `${defaultConfig.apiUrl}adminGetInterviews`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // Update interviewList state with the data received from the API
        setInterviewList(getInterviews.data);
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchData();
  }, [userData]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [employerDropdownOpen, setEmployerDropdownOpen] = useState(false);
  const [agencyDropdownOpen, setAgencyDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const toggleEmployerDropdown = () => {
    setEmployerDropdownOpen((prevState) => !prevState);
  };

  const toggleAgencyDropdown = () => {
    setAgencyDropdownOpen((prevState) => !prevState);
  };

  const handleEmployerApproval = async (employerId, employerStatus) => {
    try {
      const employerToUpdate = {
        userId: employerId,
        status: employerStatus,
      };

      const statusResponse = await axios.post(
        `${defaultConfig.apiUrl}updateUserStatus`,
        employerToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      if (statusResponse) {
        const employerArray = Object.values(employerList);
        const emp = employerArray.find(
          (employer) => employer.id === employerId
        );

        if (emp) {
          emp.status = employerStatus;
          setEmployerList(employerArray);
        } else {
          console.error("Employer Data Not Found");
        }
      } else {
        console.error("Failed to update employer status");
      }
    } catch (error) {
      console.error("Error Updating Employer Status", error.message);
    } finally {
      setEmployerDropdownOpen(false);
    }
  };

  const handleAgencyApproval = async (agencyId, agencyStatus) => {
    try {
      const agencyToUpdate = {
        userId: agencyId,
        status: agencyStatus,
      };

      const statusResponse = await axios.post(
        `${defaultConfig.apiUrl}updateUserStatus`,
        agencyToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      if (statusResponse) {
        const agencyArray = Object.values(agencyList);
        const agency = agencyArray.find((agency) => agency.id === agencyId);

        if (agency) {
          agency.status = agencyStatus;
          setAgencyList(agencyArray);
        } else {
          console.error("Agency Data Not Found");
        }
      } else {
        console.error("Failed to update agency status");
      }
    } catch (error) {
      console.error("Error Updating Agency Status", error.message);
    } finally {
      setAgencyDropdownOpen(false);
    }
  };

  const handleDisableEmployer = async (employerId, employerStatus) => {
    setDisableEmployerLoading(true);
    try {
      const employerToUpdate = {
        userId: employerId,
        status: employerStatus,
      };

      const statusResponse = await axios.post(
        `${defaultConfig.apiUrl}updateUserStatus`,
        employerToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      if (statusResponse) {
        const employerArray = Object.values(employerList);
        const emp = employerArray.find(
          (employer) => employer.id === employerId
        );

        if (emp) {
          emp.status = employerStatus;
          setEmployerList(employerArray);
          setDisableEmployerLoading(false);
        } else {
          console.error("Employer Data Not Found");
        }
      } else {
        console.error("Failed to update employer status");
      }
    } catch (error) {
      console.error("Error Updating Employer Status", error.message);
    }
  };

  const handleDisableAgency = async (agencyId, agencyStatus) => {
    setDisableAgencyLoading(true);
    try {
      const agencyToUpdate = {
        userId: agencyId,
        status: agencyStatus,
      };

      const statusResponse = await axios.post(
        `${defaultConfig.apiUrl}updateUserStatus`,
        agencyToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      if (statusResponse) {
        const agencyArray = Object.values(agencyList);
        const agency = agencyArray.find((agency) => agency.id === agencyId);

        if (agency) {
          agency.status = agencyStatus;
          setAgencyList(agencyArray);
          setDisableAgencyLoading(false);
        } else {
          console.error("Agency Data Not Found");
        }
      } else {
        console.error("Failed to update agency status");
      }
    } catch (error) {
      console.error("Error Updating Agency Status", error.message);
    }
  };

  const filterInterviewsByStatus = (status) => {
    if (!interviewList) return []; // Return an empty array if interviewList is not defined
    return Object.values(interviewList).filter(
      (interview) => interview.status === status
    );
  };

  // Get filtered lists based on employment status
  const filteredInterviewingList =
    filterInterviewsByStatus("Being Interviewed");
  const filteredHiredList = filterInterviewsByStatus("Hired");

  return (
    <section>
      <MDBContainer>
        <MDBRow>
          <h4>Admin Dashboard</h4>
          <p>Application overview</p>
        </MDBRow>
        <MDBRow>
          <MDBCol
            lg="3"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MDBCard className="mb-4" style={{ flex: "1", minWidth: "300px" }}>
              <MDBCardBody className="text-center">
                <h4>Total Employers: {employerList?.length}</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            lg="3"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MDBCard className="mb-4" style={{ flex: "1", minWidth: "300px" }}>
              <MDBCardBody className="text-center">
                <h5>
                  {filteredInterviewingList?.length} Candidates Interviewing
                </h5>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            lg="3"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MDBCard className="mb-4" style={{ flex: "1", minWidth: "300px" }}>
              <MDBCardBody className="text-center">
                <h4>{filteredHiredList?.length} Candidates Hired</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            lg="3"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MDBCard className="mb-4" style={{ flex: "1", minWidth: "300px" }}>
              <MDBCardBody className="text-center">
                <h4>{candidateList?.length} Total Candidates</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Active Interviews
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Employer
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                          <th className="text-center" style={{ width: "25%" }}>
                            Status
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {filteredInterviewingList.length > 0 ? (
                          filteredInterviewingList.map((interview, index) => (
                            <tr key={interview.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td style={{ width: "10%" }}>
                                <img
                                  src={
                                    interview?.candidate.photoUrl
                                      ? interview?.candidate.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {interview?.candidate?.firstName}{" "}
                                {interview?.candidate?.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {interview?.employer?.companyName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {interview?.jobType}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "25%" }}
                              >
                                <div className="btn-group">
                                  <Button
                                    type="button"
                                    color="secondary"
                                    className="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    size="sm"
                                  >
                                    Update
                                  </Button>
                                  <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropdown}
                                  >
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() =>
                                          handleStatusChange(
                                            interview.id,
                                            interview.jobType,
                                            "Not Hired",
                                            interviewList
                                          )
                                        }
                                        size="sm"
                                      >
                                        Available
                                      </DropdownItem>
                                      {interview.status !== "Hired" && (
                                        <DropdownItem
                                          onClick={() =>
                                            handleStatusChange(
                                              interview.id,
                                              interview.jobType,
                                              "Hired",
                                              interviewList
                                            )
                                          }
                                          size="sm"
                                        >
                                          Hire
                                        </DropdownItem>
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Candidates are currently being interviewed
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Hired Candidates{" "}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Photo
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Name
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Employer
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Position
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {filteredHiredList.length > 0 ? (
                          filteredHiredList.map((interview, index) => (
                            <tr key={interview.id}>
                              <td
                                className="text-center"
                                style={{ width: "5%" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                <img
                                  src={
                                    interview?.candidate.photoUrl
                                      ? interview?.candidate.photoUrl
                                      : DefaultPhoto
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {interview?.candidate?.firstName}{" "}
                                {interview?.candidate?.lastName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {interview?.employer?.companyName}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}
                              >
                                {interview?.jobType}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Current Hired Candidates
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Employers Awaiting Confirmation{" "}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "30%" }}>
                            Company Name
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            Prospects
                          </th>
                          <th className="text-center" style={{ width: "20%" }}>
                            Status
                          </th>
                          <th className="text-center" style={{ width: "35%" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {employerList?.length > 0 ? (
                          employerList.map((employer, index) =>
                            employer.status !== "Enabled" ? (
                              <tr key={employer.id}>
                                <td
                                  className="text-center"
                                  style={{ width: "5%" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "30%" }}
                                >
                                  {employer.companyName}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "10%" }}
                                >
                                  {employer.candidatesToHire !== null &&
                                  employer.candidatesToHire !== undefined
                                    ? employer.candidatesToHire
                                    : "-"}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "20%" }}
                                >
                                  {employer.status}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "35%" }}
                                >
                                  <div className="btn-group">
                                    <span>
                                      <Button
                                        size="sm"
                                        color="info"
                                        onClick={() => toggleModal(employer)}
                                      >
                                        View
                                      </Button>
                                    </span>{" "}
                                    <Button
                                      type="button"
                                      color="secondary"
                                      className="dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      size="sm"
                                    >
                                      Update
                                    </Button>
                                    <Dropdown
                                      isOpen={employerDropdownOpen}
                                      toggle={toggleEmployerDropdown}
                                    >
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() =>
                                            handleEmployerApproval(
                                              employer.id,
                                              "Enabled"
                                            )
                                          }
                                          size="sm"
                                        >
                                          Approve
                                        </DropdownItem>
                                        {employer.status !== "Disabled" && (
                                          <DropdownItem
                                            onClick={() =>
                                              handleDisableEmployer(
                                                employer.id,
                                                "Disabled"
                                              )
                                            }
                                            size="sm"
                                          >
                                            Decline
                                          </DropdownItem>
                                        )}
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Employers Awaiting Approval
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Active Employers
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "35%" }}>
                            Company Name
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            Prospects
                          </th>
                          <th className="text-center" style={{ width: "50%" }}>
                            Status
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {employerList?.length > 0 ? (
                          employerList.map((employer, index) =>
                            employer.status === "Enabled" ? (
                              <tr key={employer.id}>
                                <td
                                  className="text-center"
                                  style={{ width: "5%" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "35%" }}
                                >
                                  {employer.companyName}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "10%" }}
                                >
                                  {employer.candidatesToHire !== null &&
                                  employer.candidatesToHire !== undefined
                                    ? employer.candidatesToHire
                                    : "-"}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "50%" }}
                                >
                                  <div className="btn-group">
                                    <span>
                                      <Button
                                        size="sm"
                                        color="info"
                                        onClick={() => toggleModal(employer)}
                                      >
                                        View
                                      </Button>
                                    </span>{" "}
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleEmployerApproval(
                                          employer.id,
                                          "Disabled"
                                        )
                                      }
                                      size="sm"
                                    >
                                      {disableEmployerLoading
                                        ? "Loading..."
                                        : "Disable"}
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Active Employers
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Agencies Awaiting Confirmation{" "}
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "5%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "40%" }}>
                            Company Name
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            Status
                          </th>
                          <th className="text-center" style={{ width: "45%" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {agencyList?.length > 0 ? (
                          agencyList.map((agency, index) =>
                            agency.status !== "Enabled" ? (
                              <tr key={agency.id}>
                                <td
                                  className="text-center"
                                  style={{ width: "5%" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "40%" }}
                                >
                                  {agency.companyName}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "10%" }}
                                >
                                  {agency.status}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "45%" }}
                                >
                                  <div className="btn-group">
                                    <span>
                                      <Button
                                        size="sm"
                                        color="info"
                                        onClick={() => toggleModal(agency)}
                                      >
                                        View
                                      </Button>
                                    </span>{" "}
                                    <Button
                                      type="button"
                                      color="secondary"
                                      className="dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      size="sm"
                                    >
                                      Update
                                    </Button>
                                    <Dropdown
                                      isOpen={agencyDropdownOpen}
                                      toggle={toggleAgencyDropdown}
                                    >
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() =>
                                            handleAgencyApproval(
                                              agency.id,
                                              "Enabled"
                                            )
                                          }
                                          size="sm"
                                        >
                                          Approve
                                        </DropdownItem>
                                        {agency.status !== "Disabled" && (
                                          <DropdownItem
                                            onClick={() =>
                                              handleDisableAgency(
                                                agency.id,
                                                "Disabled"
                                              )
                                            }
                                            size="sm"
                                          >
                                            Decline
                                          </DropdownItem>
                                        )}
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Agencies Awaiting Approval
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4" style={{ height: "400px" }}>
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Active Agencies
                  </span>
                </MDBCardText>
                <hr />
                <MDBRow>
                  <div
                    className="table-header"
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "10%" }}>
                            #
                          </th>
                          <th className="text-center" style={{ width: "45%" }}>
                            Company Name
                          </th>
                          <th className="text-center" style={{ width: "45%" }}>
                            Status
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <Table borderless responsive size="sm" striped>
                      <tbody>
                        {agencyList?.length > 0 ? (
                          agencyList.map((agency, index) =>
                            agency.status === "Enabled" ? (
                              <tr key={agency.id}>
                                <td
                                  className="text-center"
                                  style={{ width: "10%" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "45%" }}
                                >
                                  {agency.companyName}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "45%" }}
                                >
                                  <div className="btn-group">
                                    <span>
                                      <Button
                                        size="sm"
                                        color="info"
                                        onClick={() => toggleModal(agency)}
                                      >
                                        View
                                      </Button>
                                    </span>{" "}
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleAgencyApproval(
                                          agency.id,
                                          "Disabled"
                                        )
                                      }
                                      size="sm"
                                    >
                                      {disableEmployerLoading
                                        ? "Loading..."
                                        : "Disable"}
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Employers Awaiting Approval
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Modal isOpen={isOpen} toggle={toggleModal} size="lg" centered scrollable>
        <ModalBody>
          <MDBCard>
            <MDBCardBody>
              <MDBRow>
                <MDBCol sm="3">
                  <MDBCardText>Company Name</MDBCardText>
                </MDBCol>
                <MDBCol sm="9">
                  <MDBCardText className="text-muted">
                    {employerData?.companyName}
                  </MDBCardText>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol sm="3">
                  <MDBCardText>Contact Name</MDBCardText>
                </MDBCol>
                <MDBCol sm="9">
                  <MDBCardText className="text-muted">
                    {employerData?.firstName} {employerData?.lastName}
                  </MDBCardText>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol sm="3">
                  <MDBCardText>Address</MDBCardText>
                </MDBCol>
                <MDBCol sm="9">
                  <MDBCardText className="text-muted">
                    {employerData?.address || "-"}
                  </MDBCardText>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol sm="3">
                  <MDBCardText>Email</MDBCardText>
                </MDBCol>
                <MDBCol sm="9">
                  <MDBCardText className="text-muted">
                    {employerData?.email}
                  </MDBCardText>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol sm="3">
                  <MDBCardText>Telephone</MDBCardText>
                </MDBCol>
                <MDBCol sm="9">
                  <MDBCardText className="text-muted">
                    {employerData?.phoneNumber}
                  </MDBCardText>
                </MDBCol>
              </MDBRow>
              {employerData.role === "Employer" ? (
                <div>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Prospects to Hire</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {employerData?.candidatesToHire || "-"}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                </div>
              ) : null}
            </MDBCardBody>
          </MDBCard>
        </ModalBody>
      </Modal>
    </section>
  );
}

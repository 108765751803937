import React, { useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Input, FormGroup, Label, Table, Button, Form } from "reactstrap";
import axios from "axios";
import ExperienceModal from "./WorkExperienceModal";
import defaultConfig from "../aws-configure";
import jobList from "./../jobList.json";

export default function EditProfile({ userData, setUserData }) {
  const [personalInfoLoading, setPersonalInfoLoading] = useState(false);
  const [workInfoLoading, setWorkInfoLoading] = useState(false);
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(false);
  const [address, setAddress] = useState(userData.address || "");
  const [country, setCountry] = useState(userData.country || "");
  const [gender, setGender] = useState(userData.gender || "");
  const [dob, setDob] = useState(userData.dob || "");
  const [profilePhoto, setProfilePhoto] = useState(userData.photo || "");
  const [secondaryPosition, setSecondaryPosition] = useState(
    userData.positions?.secondary || ""
  );
  const [primaryPosition, setPrimaryPosition] = useState(
    userData.positions?.primary || ""
  );
  const [educationLevel, setEducationLevel] = useState(
    userData.educationLevel || ""
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    userData.additionalInfo || ""
  );
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    userData.subscriptionStatus || ""
  );
  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [editExperienceData, setEditExperienceData] = useState(null);

  const toggleAddMode = () => {
    setModalMode("add");
    toggleModal();
  };

  const toggleEditMode = () => {
    setModalMode("edit");
    toggleModal();
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleWorkInfoSubmit = async (e) => {
    e.preventDefault();
    setWorkInfoLoading(true);

    const workDataToUpdate = {
      positions: {
        primary: primaryPosition,
        secondary: secondaryPosition,
      },
      educationLevel: educationLevel,
      additionalInfo: additionalInfo,
      // subscriptionStatus: subscriptionStatus,
    };

    try {
      const updatedUser = await axios.post(
        `${defaultConfig.apiUrl}updateUser`,
        workDataToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      setUserData(updatedUser.data);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setWorkInfoLoading(false);
    }
  };

  const handleDeleteExperience = async (index) => {
    // Create a copy of userData
    const updatedUserData = { ...userData };
    // Remove the experience at the specified index
    updatedUserData.workExperiences.splice(index, 1);
    try {
      // Make a POST request to update the user data
      const updatedUser = await axios.post(
        `${defaultConfig.apiUrl}updateUser`,
        updatedUserData, // Send the entire userData object
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      // Update the user data state with the response data
      setUserData(updatedUser.data);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setWorkInfoLoading(false);
    }
  };
  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
    setPersonalInfoLoading(true);

    const personalDataToUpdate = {
      address: address,
      country: country,
      gender: gender,
      dob: dob,
      status: "Enabled",
      // subscriptionStatus: subscriptionStatus,
    };

    try {
      const updatedUser = await axios.post(
        `${defaultConfig.apiUrl}updateUser`,
        personalDataToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      setUserData(updatedUser.data);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setPersonalInfoLoading(false);
    }
  };

  function getFileContentType(fileName) {
    const extension = fileName?.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      default:
        return "application/octet-stream"; // Default to binary data if content type is unknown
    }
  }

  const handleProfilePhotoSubmit = async (e) => {
    e.preventDefault();
    setProfilePhotoLoading(true);

    const contentType = getFileContentType(profilePhoto.name);

    const getSignedUrl = {
      fileName: profilePhoto.name,
      contentType: contentType,
    };

    try {
      const signedUrlResponse = await axios.post(
        `https://oi7gkhbw0m.execute-api.us-east-1.amazonaws.com/dev/getSignedUrl`,
        getSignedUrl,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      if (signedUrlResponse && signedUrlResponse.data) {
        const uploadFile = async () => {
          try {
            const uploadURL = signedUrlResponse.data.signedUrl;
            await axios.put(uploadURL, profilePhoto, {
              headers: {
                "Content-Type": contentType,
              },
            });
            const profilePhotoToUpdate = {
              photoUrl: signedUrlResponse.data.signedUrl.split("?")[0],
              // subscriptionStatus: subscriptionStatus,
            };

            const updatedUser = await axios.post(
              `${defaultConfig.apiUrl}updateUser`,
              profilePhotoToUpdate,
              {
                headers: {
                  Authorization: sessionStorage.getItem("userToken"),
                },
              }
            );
            setUserData(updatedUser.data);
          } catch (error) {
            console.error("Error uploading file:", error);
            // Handle upload error
          }
        };

        await uploadFile();
      }
    } catch (error) {
      console.error("There was a problem updating Profile Photo:", error);
    } finally {
      setProfilePhotoLoading(false);
    }
  };

  const handleEditExperience = (experience) => {
    setIsOpen(true);
    setModalMode("edit");
    setEditExperienceData(experience);
  };

  const sortedJobList = [...jobList].sort();

  return (
    <section>
      <MDBContainer>
        <MDBRow>
          <h4>Edit Profile</h4>
          <p>Modify your profile details here</p>
        </MDBRow>
        <MDBRow className="row-eq-height">
          <MDBCol lg="6">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form onSubmit={handlePersonalInfoSubmit}>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Personal Information
                    </span>{" "}
                    <span>
                      <Button
                        color="info"
                        size="sm"
                        type="submit"
                        disabled={personalInfoLoading}
                      >
                        {personalInfoLoading ? "Updating..." : "Update"}
                      </Button>
                    </span>
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="firstName">First Name</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={userData.firstName}
                        disabled
                      />
                    </MDBCol>
                    <MDBCol sm="6">
                      <Label for="lastName">Last Name</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={userData.lastName}
                        disabled
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="dob">Date of Birth</Label>
                      <Input
                        id="dob"
                        name="Dob"
                        type="date"
                        value={dob}
                        onChange={(e) => {
                          setDob(e.target.value);
                        }}
                      />
                    </MDBCol>
                    <MDBCol>
                      <Label for="gender">Gender</Label>
                      <Input
                        id="gender"
                        name="Gender"
                        type="select"
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Input>
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        value={userData.email}
                        disabled
                      />
                    </MDBCol>
                    <MDBCol sm="6">
                      <Label for="telephone">Telephone</Label>
                      <Input
                        id="telephone"
                        name="telephone"
                        type="tele"
                        value={userData.phoneNumber}
                        disabled
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="address">Address</Label>
                      <Input
                        id="address"
                        name="Address"
                        placeholder={
                          userData.address
                            ? userData.address
                            : "Enter Address Here"
                        }
                        type="text"
                        // value={userData.address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="country">Country</Label>
                      <Input
                        id="country"
                        name="Country"
                        type="text"
                        placeholder={
                          userData.country ? userData.country : "Country"
                        }
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="6" style={{ height: "auto" }}>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form onSubmit={handleProfilePhotoSubmit}>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Profile Photo
                    </span>{" "}
                    <span>
                      <Button
                        color="info"
                        size="sm"
                        type="submit"
                        disabled={profilePhotoLoading}
                      >
                        {profilePhotoLoading ? "Updating..." : "Update"}
                      </Button>
                    </span>
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="12">
                      {/* <Label for="additionalInfo">Profile Photo</Label> */}
                      <Input
                        id="profilePhoto"
                        name="profilePhoto"
                        type="file"
                        onChange={(e) => {
                          setProfilePhoto(e.target.files[0]);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form onSubmit={handleWorkInfoSubmit}>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Work Information
                    </span>{" "}
                    <span>
                      <Button
                        color="info"
                        size="sm"
                        type="submit"
                        disabled={workInfoLoading}
                      >
                        {workInfoLoading ? "Updating..." : "Update"}
                      </Button>
                    </span>
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="primaryPosition">Primary Position</Label>
                        <Input
                          id="primaryPosition"
                          name="primaryPosition"
                          type="select"
                          value={primaryPosition}
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                          onChange={(e) => {
                            setPrimaryPosition(e.target.value);
                          }}
                        >
                          <option value="">Select Position</option>
                          {sortedJobList.map((job) => (
                            <option key={job} value={job}>
                              {job}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </MDBCol>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="secondaryPosition">
                          Secondary Position
                        </Label>
                        <Input
                          id="secondaryPosition"
                          name="secondaryPosition"
                          type="select"
                          value={secondaryPosition}
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                          onChange={(e) => {
                            setSecondaryPosition(e.target.value);
                          }}
                        >
                          <option value="">Select Position</option>
                          {sortedJobList.map((job) => (
                            <option key={job} value={job}>
                              {job}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="educationLevel">Education Level</Label>
                        <Input
                          id="educationLevel"
                          name="educationLevel"
                          type="select"
                          value={educationLevel}
                          onChange={(e) => {
                            setEducationLevel(e.target.value);
                          }}
                        >
                          <option value="">Select Education Level</option>
                          <option value="High School Diploma">
                            High School Diploma
                          </option>
                          <option value="Some College">Some College</option>
                          <option value="Associate Degree">
                            Associate Degree
                          </option>
                          <option value="Bachelor's Degree">
                            Bachelor's Degree
                          </option>
                        </Input>
                      </FormGroup>
                    </MDBCol>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="firstTimer">First Timer</Label>
                        <Input
                          id="firstTimer"
                          name="firstTimer"
                          type="text"
                          value={userData.firstTimer === "true" ? "Yes" : "No"}
                          disabled
                        ></Input>
                      </FormGroup>
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="additionalInfo">Additional Information</Label>
                      <Input
                        id="additionalInfo"
                        name="additionalInfo"
                        type="textarea"
                        placeholder={
                          userData.additionalInfo
                            ? userData.additionalInfo
                            : "Any additional information?"
                        }
                        onChange={(e) => {
                          setAdditionalInfo(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Work Experiences
                  </span>{" "}
                  <span>
                    <Button color="primary" size="sm" onClick={toggleAddMode}>
                      Add New
                    </Button>
                  </span>
                  <ExperienceModal
                    isOpen={isOpen}
                    toggle={toggleModal}
                    userData={userData}
                    setUserData={setUserData}
                    mode={modalMode}
                    onEdit={handleEditExperience}
                    toggleEditMode={toggleEditMode}
                    editExperienceData={editExperienceData}
                  />
                </MDBCardText>
                <hr />
                <Table borderless responsive size="sm" striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Position</th>
                      <th>Country</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!userData.workExperiences ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Please add a work experience.
                        </td>
                      </tr>
                    ) : (
                      userData.workExperiences?.map((experience, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{experience?.companyName}</td>
                          <td>{experience?.position}</td>
                          <td>
                            {experience?.state}, {experience?.country}
                          </td>
                          <td>
                            {experience?.startDate} - {experience?.endDate}
                          </td>
                          <td>
                            <span>
                              <Button
                                color="info"
                                size="sm"
                                onClick={() => handleEditExperience(experience)}
                              >
                                Edit
                              </Button>
                            </span>{" "}
                            <span>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => handleDeleteExperience(index)}
                              >
                                Delete
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {/* <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Change Password
                    </span>
                    <hr />
                  </MDBCardText>
                  <MDBRow>
                    <MDBCol sm="4">
                      <Label for="currentPassword">Current Password</Label>
                      <Input
                        id="currentPassword"
                        name="currentPassword"
                        type="password"
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="4">
                      <Label for="newPassword">New Password</Label>
                      <Input
                        id="newPassword"
                        name="newPassword"
                        type="password"
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="4">
                      <Label for="confirmPassword">Confirm Password</Label>
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="4">
                      <span>
                        <Button color="success" size="sm">
                          Change
                        </Button>
                      </span>
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow> */}
      </MDBContainer>
    </section>
  );
}

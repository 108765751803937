import React from "react";
import Hero from "../components/Hero";
import ImageCard from "../components/ImageCard";
import CandidateImage from "../images/CandidateCard.png";
import EmployerDisplay from "../images/Group 227.png";

export default function Home() {
  const hero = {
    image: require("../images/AdobeStock_189707570.jpg"),
    headerText: "Welcome",
    subText: "One H2B Community - Advocating, Connecting, Simplifying",
  };

  const pathCandidate = "/candidates";
  const pathRecruiter = "/employers";

  return (
    <div>
      <div>
        <Hero
          image={hero.image}
          headerText={hero.headerText}
          subText={hero.subText}
        />
      </div>
      <div className="">
        <div className="blue-background">
          <p className="text-center">
            The H2B Program is not dying or difficult, not even close. It is
            more prevalent than it has been in years! The resilient program has
            seen its most successful year in 2022 from it’s past 60 years of
            operation.
          </p>
          {/* <p className="text-center">
            Luckily, H2B Direct has answers to all of these questions. H2B
            Direct as over 10,000+ H2B Applicants from various H2B friendly
            countries. We have a library of agents from every country who can
            assist with screening, interviews, embassy preparations and travel
            arrangements with these h2b candidates. In addition we have a
            step-by-step tutorial as to how to file for and hopefully acquire
            your first H2B Petition. We have an even bigger library with H2B
            Advocates, Immigration lawyers, and legal aids who help with filing
            and guidelines.
          </p> */}
        </div>
        <div className="alt-section">
          <ImageCard
            hasHeader="true"
            cardHeader="H2B Candidates"
            imageSide="left"
            cardParagraph="We are here to help! H2B can be quite stressful finding agencies or employers by
            yourself. It can sometimes take several years to secure a successful placement
            in the US. H2B Direct, reaches candidates from over 20 countries, we house their
            face-featured profiles on our site so that employers with H2B openings can find
            them easily. You can then communicate directly with your employers for quick
            and easy processing. Just put your best foot forward, In 5 easy steps you can
            complete the H2B Direct question sheet so your profile can look like the one
            shown in the example below. Agencies, Immigration Lawyers, Housing agents
            and H2B resources are all listed on our platform for better connectivity..."
            showbtn="true"
            btnTitle="Learn More"
            pathToPage={pathCandidate}
            cardPhoto={CandidateImage}
          />
        </div>
        <div>
          <ImageCard
            hasHeader="true"
            cardHeader="Employers"
            imageSide="right"
            cardParagraph="Seeking International workers? You are in the right place. On H2BDirect you are
            able to hand-pick candidates from across the world, by viewing consice photo
            profiles showing their experiences which will be valuable to your workforce.
            Whether you are hiring 2 or 500 Candidates, you have access to thousands of
            eager international workers in one central location on an easy-to-use platform
            with filter options to find your best candidates. We also have Immigration
            Lawyers, Local agents to assist with Consulate/Embassy visits, Housing agents
            and J1, Q1, L, O and C1D hiring options and many more resources."
            showbtn="true"
            btnTitle="Learn More"
            pathToPage={pathRecruiter}
            cardPhoto={EmployerDisplay}
          />
        </div>
        {/* <div className="alt-section">
          <TestimonialSlider />
        </div> */}
        {/* <div className="contact-us-section">
          <p className="text-center">NEED MORE INFORMATION</p>
          <h1 className="text-center">Get In Touch</h1>
          <br></br>
          <div className="contact-form-area">
            <form className="home-form">
              <div className="row mb-4">
                <div className="col ">
                  <div className="form-outline">
                    <input
                      type="text"
                      id="firstName"
                      className="form-control"
                    />
                    <label className="form-label" htmlFor="firstName">
                      First Name
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <input type="text" id="lastName" className="form-control" />
                    <label className="form-label" htmlFor="lastName">
                      Last Name
                    </label>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <input type="email" id="email" className="form-control" />
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <input
                      type="text"
                      id="telephone"
                      className="form-control"
                    />
                    <label className="form-label" htmlFor="telephone">
                      Telephone
                    </label>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="form-outline mb-4">
                <textarea type="text" id="message" className="form-control" />
                <label className="form-label" htmlFor="message">
                  Message
                </label>
              </div>

              <button type="submit" className="btn btn-primary btn-lg mb-4">
                Submit
              </button>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  );
}

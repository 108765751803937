import React, { useEffect, useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { Modal, ModalBody, Button, ModalFooter } from "reactstrap";
import Stepper from "react-stepper-horizontal";
import DefaultPhoto from "./../images/DefaultPhoto.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import defaultConfig from "../aws-configure";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from "./Checkout";

const initialOptions = {
  "client-id": defaultConfig.paypalClientId,
  currency: "USD",
  intent: "capture",
};

export default function ProfilePage() {
  const [userData, setUserData] = useState(null);
  const [newUserModal, setNewUserModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("userToken");

        const userDataResponse = await axios.get(
          `${defaultConfig.apiUrl}getUserById`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setUserData(userDataResponse.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userData && userData.status !== "Enabled") {
      setNewUserModal(true);
    }
  }, [userData]);

  const toggle = () => {
    setNewUserModal(!newUserModal);
  };

  const navigate = useNavigate();

  const handleNewModal = () => {
    setNewUserModal(!newUserModal);
    navigate("/app/candidate/edit");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const paymentToggle = () => {
    setPaymentModal(!paymentModal);
  };

  return (
    <section>
      <Modal isOpen={paymentModal} toggle={paymentToggle} centered>
        <ModalBody>
          <PayPalScriptProvider options={initialOptions}>
            <Checkout
              userData={userData}
              setUserData={setUserData}
              paymentModal={paymentModal}
              setPaymentModal={setPaymentModal}
            />
          </PayPalScriptProvider>
        </ModalBody>
      </Modal>
      <Modal isOpen={newUserModal} toggle={toggle} size="md" centered>
        <ModalBody>
          <p>
            Your profile information needs to be updated before it can be
            visible to employers. Keeping your profile up-to-date ensures that
            you present yourself effectively to potential employers.
          </p>

          <p>
            Please take a moment to review and update your profile information.
            Once updated, you can proceed to make payment of your yearly
            subscription fee before your account will be visible to employers
            seeking candidates with your qualifications and skills.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleNewModal}>
            Update Profile
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <h4>Profile Overview</h4>
            <p>See your profile the way your employer does</p>
          </MDBCol>
          <MDBCol lg="2">
            <Button
              onClick={paymentToggle}
              disabled={userData?.subscriptionStatus === "Active"}
              color={
                userData?.subscriptionStatus === "Active"
                  ? "success"
                  : "primary"
              }
            >
              {userData?.subscriptionStatus === "Active"
                ? "Payment Made"
                : "Make Payment - $74.99"}
            </Button>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="3">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={userData?.photoUrl ? userData.photoUrl : DefaultPhoto}
                  alt="avatar"
                  className="rounded"
                  style={{
                    width: "220px",
                    height: "220px",
                    maxHeight: "220px",
                    paddingBottom: "10px",
                  }}
                  onError={(e) => {
                    e.target.src = DefaultPhoto;
                  }}
                  fluid
                />
                <h2 className="text-muted mb-1">
                  {userData && `${userData.firstName} ${userData.lastName}`}
                </h2>
                <div className="d-flex justify-content-center mb-1">
                  <p>
                    <span className="badge rounded-pill bg-primary">
                      {userData &&
                        userData.positions &&
                        userData.positions.primary}
                    </span>{" "}
                    <span className="badge rounded-pill bg-secondary">
                      {userData &&
                        userData.positions &&
                        userData.positions.secondary}
                    </span>
                  </p>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="9">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {userData && userData.email}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Telephone Number</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {userData && userData.phoneNumber}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {userData && userData.address && userData.country
                        ? `${userData.address}, ${userData.country}`
                        : ""}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Gender</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {userData && userData.gender ? userData.gender : ""}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Education Level</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {userData && userData.educationLevel
                        ? userData.educationLevel
                        : ""}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>First Time Applicant</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {userData && userData.firstTimer === "true"
                        ? "Yes"
                        : "No"}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <Stepper
                  steps={[
                    { title: "Open" },
                    { title: "Awaiting Interview" },
                    { title: "Hired" },
                  ]}
                  activeStep={
                    userData && userData.employmentStatus === "Available"
                      ? 0
                      : userData &&
                        userData.employmentStatus === "Being Interviewed"
                      ? 1
                      : 2
                  }
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {userData &&
            userData.workExperiences &&
            userData.workExperiences.map((experience, index) => (
              <MDBCol md="6" key={index}>
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBCardText className="mb-4">
                      <span className="text-primary font-italic me-1">
                        Work Experience
                      </span>
                    </MDBCardText>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Company</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {experience.companyName}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Location</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {experience.state}, {experience.country}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Position</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {experience.position}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Start Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {experience.startDate}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>End Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {experience.endDate}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="12" className="mb-2">
                        <MDBCardText>Job Description</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="12">
                        <MDBCardText className="text-muted">
                          {experience.jobDescription}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

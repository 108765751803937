import React from "react";

export default function Hero(props) {
  const { image, headerText, subText } = props;
  return (
    <div
      className="p-5 bg-image"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        height: "75vh",
      }}
    >
      <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}>
        <div className="d-flex container align-items-center h-100">
          <div className="text-white">
            <h1 className="mb-3">{headerText}</h1>
            <h5 className="mb-3">{subText}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

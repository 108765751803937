import React, { useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Input, FormGroup, Label, Table, Button, Form } from "reactstrap";
import axios from "axios";
import AdminExperienceModal from "./AdminWorkExperienceModal";
import defaultConfig from "../aws-configure";
import jobList from "./../jobList.json";

export default function AdminEditProfile({ candidateData, setCandidateData }) {
  const [personalInfoLoading, setPersonalInfoLoading] = useState(false);
  const [workInfoLoading, setWorkInfoLoading] = useState(false);
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(false);
  const [address, setAddress] = useState(candidateData.address || "");
  const [country, setCountry] = useState(candidateData.country || "");
  const [gender, setGender] = useState(candidateData.gender || "");
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    candidateData.subscriptionStatus || ""
  );
  const [firstTimer, setFirstTimer] = useState(candidateData.firstTimer || "");
  const [phoneNumber, setPhoneNumber] = useState(
    candidateData.phoneNumber || ""
  );
  const [dob, setDob] = useState(candidateData.dob || "");
  const [profilePhoto, setProfilePhoto] = useState(
    candidateData.photoUrl || ""
  );
  const [secondaryPosition, setSecondaryPosition] = useState(
    candidateData.positions?.secondary || ""
  );
  const [primaryPosition, setPrimaryPosition] = useState(
    candidateData.positions?.primary || ""
  );
  const [educationLevel, setEducationLevel] = useState(
    candidateData.educationLevel || ""
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    candidateData.additionalInfo || ""
  );
  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [editExperienceData, setEditExperienceData] = useState(null);

  const toggleAddMode = () => {
    setModalMode("add");
    toggleModal();
  };

  const toggleEditMode = () => {
    setModalMode("edit");
    toggleModal();
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleWorkInfoSubmit = async (e) => {
    e.preventDefault();
    setWorkInfoLoading(true);

    const workDataToUpdate = {
      userId: candidateData.id,
      role: candidateData.role,
      positions: {
        primary: primaryPosition,
        secondary: secondaryPosition,
      },
      educationLevel: educationLevel,
      firstTimer: firstTimer,
      additionalInfo: additionalInfo,
      // subscriptionStatus: subscriptionStatus,
    };

    try {
      const updatedUser = await axios.post(
        `${defaultConfig.apiUrl}adminUpdateUser`,
        workDataToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      setCandidateData(updatedUser.data);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setWorkInfoLoading(false);
    }
  };

  const handleDeleteExperience = async (index) => {
    // Create a copy of candidateData
    const updatedCandidateData = {
      userId: candidateData.id,
      role: candidateData.role,
      ...candidateData,
    };
    // Remove the experience at the specified index
    updatedCandidateData.workExperiences.splice(index, 1);
    try {
      // Make a POST request to update the user data
      const updatedUser = await axios.post(
        `${defaultConfig.apiUrl}adminUpdateUser`,
        updatedCandidateData, // Send the entire candidateData object
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      // Update the user data state with the response data
      setCandidateData(updatedUser.data);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setWorkInfoLoading(false);
    }
  };
  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
    setPersonalInfoLoading(true);

    const personalDataToUpdate = {
      userId: candidateData.id,
      role: candidateData.role,
      address: address,
      country: country,
      gender: gender,
      dob: dob,
      phoneNumber: phoneNumber,
      status: "Enabled",
      // subscriptionStatus: subscriptionStatus,
    };

    try {
      const updatedUser = await axios.post(
        `${defaultConfig.apiUrl}adminUpdateUser`,
        personalDataToUpdate,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );
      setCandidateData(updatedUser.data);
    } catch (error) {
      console.error("There was a problem updating user data:", error);
    } finally {
      setPersonalInfoLoading(false);
    }
  };

  function getFileContentType(fileName) {
    const extension = fileName?.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      default:
        return "application/octet-stream"; // Default to binary data if content type is unknown
    }
  }

  const handleProfilePhotoSubmit = async (e) => {
    e.preventDefault();
    setProfilePhotoLoading(true);

    const contentType = getFileContentType(profilePhoto.name);

    const getSignedUrl = {
      fileName: profilePhoto.name,
      contentType: contentType,
    };

    try {
      const signedUrlResponse = await axios.post(
        `${defaultConfig.apiUrl}getSignedUrl`,
        getSignedUrl,
        {
          headers: {
            Authorization: sessionStorage.getItem("userToken"),
          },
        }
      );

      if (signedUrlResponse && signedUrlResponse.data) {
        const uploadFile = async () => {
          try {
            const uploadURL = signedUrlResponse.data.signedUrl;
            await axios.put(uploadURL, profilePhoto, {
              headers: {
                "Content-Type": contentType,
              },
            });
            const profilePhotoToUpdate = {
              userId: candidateData.id,
              role: candidateData.role,
              photoUrl: signedUrlResponse.data.signedUrl.split("?")[0],
              // subscriptionStatus: subscriptionStatus,
            };

            const updatedUser = await axios.post(
              `${defaultConfig.apiUrl}adminUpdateUser`,
              profilePhotoToUpdate,
              {
                headers: {
                  Authorization: sessionStorage.getItem("userToken"),
                },
              }
            );
            setCandidateData(updatedUser.data);
          } catch (error) {
            console.error("Error uploading file:", error);
            // Handle upload error
          }
        };

        await uploadFile();
      }
    } catch (error) {
      console.error("There was a problem updating Profile Photo:", error);
    } finally {
      setProfilePhotoLoading(false);
    }
  };

  const handleEditExperience = (experience, candidate) => {
    setIsOpen(true);
    setModalMode("edit");
    setEditExperienceData(experience);
    setCandidateData(candidate);
  };

  const sortedJobList = [...jobList].sort();

  return (
    <section>
      <MDBContainer>
        <MDBRow>
          <h4>Edit Profile</h4>
          <p>Modify your profile details here</p>
        </MDBRow>
        <MDBRow className="row-eq-height">
          <MDBCol lg="6">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form onSubmit={handlePersonalInfoSubmit}>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Personal Information
                    </span>{" "}
                    <span>
                      <Button
                        color="info"
                        size="sm"
                        type="submit"
                        disabled={personalInfoLoading}
                      >
                        {personalInfoLoading ? "Updating..." : "Update"}
                      </Button>
                    </span>
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="firstName">First Name</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        type="text"
                        defaultValue={candidateData.firstName}
                        disabled
                      />
                    </MDBCol>
                    <MDBCol sm="6">
                      <Label for="lastName">Last Name</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        type="text"
                        defaultValue={candidateData.lastName}
                        disabled
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="dob">Date of Birth</Label>
                      <Input
                        id="dob"
                        name="Dob"
                        type="date"
                        defaultValue={dob}
                        onChange={(e) => {
                          setDob(e.target.value);
                        }}
                      />
                    </MDBCol>
                    <MDBCol>
                      <Label for="gender">Gender</Label>
                      <Input
                        id="gender"
                        name="Gender"
                        type="select"
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Input>
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        defaultValue={candidateData.email}
                        disabled
                      />
                    </MDBCol>
                    <MDBCol sm="6">
                      <Label for="phoneNumber">Telephone</Label>
                      <Input
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        defaultValue={candidateData.phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="address">Address</Label>
                      <Input
                        id="address"
                        name="Address"
                        placeholder={
                          candidateData.address
                            ? candidateData.address
                            : "Enter Address Here"
                        }
                        type="text"
                        // value={candidateData.address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="country">Country</Label>
                      <Input
                        id="country"
                        name="Country"
                        type="text"
                        placeholder={
                          candidateData.country
                            ? candidateData.country
                            : "Country"
                        }
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="6" style={{ height: "auto" }}>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form onSubmit={handleProfilePhotoSubmit}>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Profile Photo
                    </span>{" "}
                    <span>
                      <Button
                        color="info"
                        size="sm"
                        type="submit"
                        disabled={profilePhotoLoading}
                      >
                        {profilePhotoLoading ? "Updating..." : "Update"}
                      </Button>
                    </span>
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="12">
                      {/* <Label for="additionalInfo">Profile Photo</Label> */}
                      <Input
                        id="profilePhoto"
                        name="profilePhoto"
                        type="file"
                        onChange={(e) => {
                          setProfilePhoto(e.target.files[0]);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <Form onSubmit={handleWorkInfoSubmit}>
                  <MDBCardText className="mb-4">
                    <span className="text-primary font-italic me-1">
                      Work Information
                    </span>{" "}
                    <span>
                      <Button
                        color="info"
                        size="sm"
                        type="submit"
                        disabled={workInfoLoading}
                      >
                        {workInfoLoading ? "Updating..." : "Update"}
                      </Button>
                    </span>
                  </MDBCardText>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="primaryPosition">Primary Position</Label>
                        <Input
                          id="primaryPosition"
                          name="primaryPosition"
                          type="select"
                          value={primaryPosition}
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                          onChange={(e) => {
                            setPrimaryPosition(e.target.value);
                          }}
                        >
                          <option value="">Select Position</option>
                          {sortedJobList.map((job) => (
                            <option key={job} value={job}>
                              {job}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </MDBCol>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="secondaryPosition">
                          Secondary Position
                        </Label>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            maxHeight: "200px",
                            overflowY: "auto",
                          }}
                        >
                          <Input
                            id="secondaryPosition"
                            name="secondaryPosition"
                            type="select"
                            value={secondaryPosition}
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                            onChange={(e) => {
                              setSecondaryPosition(e.target.value);
                            }}
                          >
                            <option value="">Select Position</option>
                            {sortedJobList.map((job) => (
                              <option key={job} value={job}>
                                {job}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </FormGroup>
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="educationLevel">Education Level</Label>
                        <Input
                          id="educationLevel"
                          name="educationLevel"
                          type="select"
                          value={educationLevel}
                          onChange={(e) => {
                            setEducationLevel(e.target.value);
                          }}
                        >
                          <option value="">Select Education Level</option>
                          <option value="High School Diploma">
                            High School Diploma
                          </option>
                          <option value="Some College">Some College</option>
                          <option value="Associate Degree">
                            Associate Degree
                          </option>
                          <option value="Bachelor's Degree">
                            Bachelor's Degree
                          </option>
                        </Input>
                      </FormGroup>
                    </MDBCol>
                    <MDBCol sm="6">
                      <FormGroup>
                        <Label for="firstTimer">First Timer</Label>
                        <Input
                          id="firstTimer"
                          name="firstTimer"
                          type="select"
                          defaultValue={firstTimer === "true" ? "Yes" : "No"}
                          disabled
                          // onChange={(e) => setFirstTimer(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </Input>
                      </FormGroup>
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol sm="12">
                      <Label for="additionalInfo">Additional Information</Label>
                      <Input
                        id="additionalInfo"
                        name="additionalInfo"
                        type="textarea"
                        placeholder={
                          candidateData.additionalInfo
                            ? candidateData.additionalInfo
                            : "Any additional information?"
                        }
                        onChange={(e) => {
                          setAdditionalInfo(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="text-primary font-italic me-1">
                    Work Experiences
                  </span>{" "}
                  <span>
                    <Button color="primary" size="sm" onClick={toggleAddMode}>
                      Add New
                    </Button>
                  </span>
                  <AdminExperienceModal
                    isOpen={isOpen}
                    toggle={toggleModal}
                    candidateData={candidateData}
                    setCandidateData={setCandidateData}
                    mode={modalMode}
                    onEdit={handleEditExperience}
                    toggleEditMode={toggleEditMode}
                    editExperienceData={editExperienceData}
                  />
                </MDBCardText>
                <hr />
                <Table borderless responsive size="sm" striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Position</th>
                      <th>Country</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!candidateData.workExperiences ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Please add a work experience.
                        </td>
                      </tr>
                    ) : (
                      candidateData.workExperiences?.map(
                        (experience, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{experience?.companyName}</td>
                            <td>{experience?.position}</td>
                            <td>
                              {experience?.state}, {experience?.country}
                            </td>
                            <td>
                              {experience?.startDate} - {experience?.endDate}
                            </td>
                            <td>
                              <span>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    handleEditExperience(
                                      experience,
                                      candidateData
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              </span>{" "}
                              <span>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => handleDeleteExperience(index)}
                                >
                                  Delete
                                </Button>
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </Table>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

import React, { useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import Stepper from "react-stepper-horizontal";
import DefaultPhoto from "./../images/DefaultPhoto.jpg";
import AdminEditProfile from "./AdminEditProfile";

export default function AdminCandidateCard({
  candidateData,
  setCandidateData,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleEditModal = () => {
    setIsEditOpen(!isEditOpen);
  };

  let primaryPosition =
    candidateData &&
    candidateData?.positions &&
    candidateData.positions?.primary;

  let secondaryPosition =
    candidateData &&
    candidateData.positions &&
    candidateData.positions.secondary;

  return (
    <div className="container flex-fill candidate-cards">
      <div className="card" style={{ borderRadius: "15px" }}>
        <div className="card-body p-4">
          <div className="text-center text-black">
            <div className="">
              <img
                src={
                  candidateData?.photoUrl
                    ? candidateData.photoUrl
                    : DefaultPhoto
                }
                alt="Generic placeholder"
                className="img-fluid"
                style={{
                  width: "180px",
                  maxHeight: "180px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div className="">
              <h5 className="mb-1" style={{ paddingTop: "10px" }}>
                {candidateData.firstName} {candidateData.lastName}
              </h5>
              <p className="mb-2 pb-1" style={{ color: "#2b2a2a" }}>
                {candidateData.country}
              </p>
              <div
                className="d-flex justify-content rounded-3 p-2 mb-2"
                style={{ backgroundColor: "#efefef" }}
              >
                <div className="col-lg-6">
                  <p className="small  text-muted mb-1">Position 1</p>
                  <p className="mb-0">
                    <span className="badge rounded-pill bg-primary">
                      {candidateData.positions?.primary ===
                      "Food and Beverage Attendants"
                        ? "F&B Attendants"
                        : candidateData.positions?.primary}
                    </span>{" "}
                  </p>
                </div>
                <div className="col-lg-6">
                  <p className="small text-muted mb-1">Position 2</p>
                  <span className="badge rounded-pill bg-secondary">
                    {candidateData.positions?.secondary ===
                    "Food and Beverage Attendants"
                      ? "F&B Attendants"
                      : candidateData.positions?.secondary}
                  </span>
                </div>
              </div>
              <div className="d-flex pt-1">
                <span>
                  <Button onClick={toggleModal}>View More</Button>
                </span>
                <span style={{ marginLeft: "20px" }}>
                  <Button color="primary" onClick={toggleEditModal}>
                    Edit
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} toggle={toggleModal} size="xl" centered scrollable>
        <ModalBody className="modal-height">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="4">
                <MDBCard className="mb-4">
                  <MDBCardBody className="text-center">
                    <MDBCardImage
                      src={
                        candidateData?.photoUrl
                          ? candidateData.photoUrl
                          : DefaultPhoto
                      }
                      alt="avatar"
                      className="rounded"
                      style={{ width: "220px", paddingBottom: "10px" }}
                      fluid
                    />
                    <h2 className="text-muted mb-1">
                      {candidateData &&
                        `${candidateData.firstName} ${candidateData.lastName}`}
                    </h2>
                    <div className="d-flex justify-content-center mb-1">
                      <MDBCol sm="6">
                        <span className="badge rounded-pill bg-primary">
                          {candidateData &&
                            candidateData.positions &&
                            candidateData.positions.primary}
                        </span>{" "}
                      </MDBCol>
                      <MDBCol sm="6">
                        <span className="badge rounded-pill bg-secondary">
                          {candidateData &&
                            candidateData.positions &&
                            candidateData.positions.secondary}
                        </span>
                      </MDBCol>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol lg="8">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Email Address</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && `${candidateData.email}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Telephone Number</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && `${candidateData.phoneNumber}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Address</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData &&
                            `${candidateData.address}, ${candidateData.country}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Gender</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && `${candidateData.gender}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Education Level</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && `${candidateData.educationLevel}`}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>First Time Applicant</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {candidateData && candidateData.firstTimer === "true"
                            ? "Yes"
                            : "No"}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol lg="12">
                <MDBCard className="mb-4">
                  <MDBCardBody className="text-center">
                    <Stepper
                      steps={[
                        { title: "Open" },
                        { title: "Awaiting Interview" },
                        { title: "Hired" },
                      ]}
                      activeStep={
                        candidateData &&
                        candidateData.employmentStatus === "Available"
                          ? 0
                          : candidateData &&
                            candidateData.employmentStatus ===
                              "Being Interviewed"
                          ? 1
                          : 2
                      }
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {candidateData &&
                candidateData.workExperiences &&
                candidateData.workExperiences.map((experience, index) => (
                  <MDBCol md="6" key={index}>
                    <MDBCard className="mb-4">
                      <MDBCardBody>
                        <MDBCardText className="mb-4">
                          <span className="text-primary font-italic me-1">
                            Work Experience
                          </span>
                        </MDBCardText>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Company</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.companyName}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Location</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.state}, {experience.country}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Position</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.position}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Start Date</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.startDate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>End Date</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {experience.endDate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="12" className="mb-2">
                            <MDBCardText>Job Description</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="12">
                            <MDBCardText className="text-muted">
                              {experience.jobDescription}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
            </MDBRow>
          </MDBContainer>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isEditOpen}
        toggle={toggleEditModal}
        size="xl"
        centered
        scrollable
      >
        <ModalBody className="modal-height">
          <AdminEditProfile
            candidateData={candidateData}
            setCandidateData={setCandidateData}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

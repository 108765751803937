import React from "react";
import { Drawer, List, Toolbar } from "@mui/material";
import sizeConfigs from "./../../configs/sizeConfigs";
import colorConfigs from "./../../configs/colorConfig";
// import Logo from "./../../images/H2B-DirectLogo-cropped.png";
import appRoutes from "./../../routes/appRoutes";
import SidebarItem from "./SidebarItems";

function Sidebar({ userData }) {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
        },
      }}
    >
      <List disablePadding>
        <Toolbar
          sx={{
            marginBottom: "20px",
          }}
        >
          {/* <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <Avatar src={Logo} />
          </Stack> */}
        </Toolbar>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            <SidebarItem item={route} key={index} userData={userData} />
          ) : null
        )}
      </List>
    </Drawer>
  );
}

export default Sidebar;
